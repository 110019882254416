<script>
export default {
    name: 'MySubscription',
}
</script>
<template>
    <div>
        我的订购记录
    </div>
</template>
<style>

</style>