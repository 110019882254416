<template>
    <div>
        <el-dialog v-model="data.centerDialogVisible" width="320" center :close-on-click-modal="false"
                   :close-on-press-escape="false" @close="closeDialog">
            <div class="flex flex-col items-center">
                <div>请使用淘特（淘宝特价版）APP扫码授权</div>
                <div class="relative ">
                    <el-image :src="data.qrcodeUrl"></el-image>
                    <div class="absolute qrcode" v-if="data.isRetrieve">
                        <el-button type="primary" @click="empower(true)">重新获取授权码</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
<!--       <TaoteGoodsList></TaoteGoodsList>-->
        <el-tabs  class="panBuXiangKAN mb-20" v-model="data.active">
<!--            type="border-card"-->
            <el-tab-pane v-for="item in tabs" :key="item.id" :label="item.title" :name="item.id">
                <KeepAlive>
                    <component v-if="item.id === data.active" :is="item.comp" :category="data.category"
                               :isEmpower="data.isEmpower"
                               @empower="empower"></component>
                </KeepAlive>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import {getBUserPerms, getAuthTaoteStatus, getBItemCategory} from "@/api/taoteList"
// import {ElMessage} from 'element-plus';
// import TaoteGoodsList from "./components/taoteGoodsList.vue"
import TaoteGoodsList from "@/views/SourceCenter/components/taoteGoodsList";
// import ManualSelection from "./components/manualSelection.vue"

export default {
    components: {TaoteGoodsList},
    data() {
        return {
            data: {
                centerDialogVisible: false,
                qrcodeUrl: "",
                timer: null,
                isRetrieve: false,
                category: [],
                active: 1,
                isEmpower: false
            },
            tabs: [
                {id: 1, title: "精选商品", comp: TaoteGoodsList},
                // {id: 2, title: "淘特榜单", comp: ManualSelection},
            ]
        }
    },
    mounted() {
        // 获取商品类目
        getBItemCategory({parentCatId: 0}).then(res => {
            this.data.category = res.data.data
        })
// 查看是否授权
        getBUserPerms().then(res => {
            console.log(res)
            this.data.isEmpower= true
        })
    },
    watch: {},
    methods: {
        // 关闭弹窗
         closeDialog  () {
            clearInterval(this.data.timer)
        },
// 淘特授权
         empower  (bool)  {
            getBUserPerms().then(res => {
                console.log(res)
                this.data.isEmpower   = true
            }).catch(err => {
                let {code, data} = err
                if (code === 5) {
                    if (bool) {
                       this.$message.success("重新获取成功，请扫码授权")
                        this.data.isRetrieve   = false
                    }
                    this.data.centerDialogVisible   = true
                    this.data.qrcodeUrl   =data.qrCodeUrl
                    let ind = 0
                    this.data.timer   = setInterval(() => {
                        ind++
                        if (ind * 5 > data.expire_in) {
                            this.$message.warning("授权码过期，请重新获取授权码")
                            this.data.isRetrieve   = true
                            clearInterval(this.data.timer  )
                            return
                        }
                        // 查看授权结果
                        getAuthTaoteStatus({tempCode: data.tempCode}).then(res => {
                            console.log(res)
                            this.$message.success("授权成功")
                            this.data.isEmpower  = true
                            clearInterval(this.data.timer )
                            this.data.centerDialogVisible   = false
                        }).catch(err => {
                            console.log(err)
                        })
                    }, 5000)
                }
            })
        }
    }
}


</script>

<style>

.panBuXiangKAN .el-tabs__nav-scroll{
    display: none!important;
}
.qrcode {
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>