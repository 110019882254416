<template>
    <div>

        <div class="bg-white p-2 rounded-md bgGOU">
            <h3 class="text-2xl mb-4 font-bold body_color items-center title">淘特货源</h3>
            <el-form>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-form-item>
                            <el-input placeholder="请输入需要搜索关键字" v-model="data.q"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="价格区间：">
                            <div class="flex">
                                <el-input placeholder="请输入" v-model="data.minPrice"></el-input>
                                &nbsp;-&nbsp;
                                <el-input placeholder="请输入" v-model="data.maxPrice"></el-input>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="排序：">
                            <el-select v-model="data.sort">
                                <el-option v-for="item in sortList" :key="item.value" :value="item.value"
                                           :label="item.label"/>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-button type="primary" @click="getList(1)">查询</el-button>
                </el-row>
            </el-form>
            <div class="flex text-sm mt-3 justify-start items-center">
                <span class="color_gray">商品池：</span>
                <ul class="flex flex-wrap">
                    <li class="mx-2 cursor-pointer li_hover font-bold px-2 py-1 transition_all" v-for="item in data.productPool"
                        :key="item.poolId" @click="categoryQuery(item.poolId, 'poolId')"
                        :style="{ color: item.poolId === data.poolId ? '#019943' : '' }">{{ item.poolName }}
                    </li>
                </ul>
            </div>
            <el-divider border-style="dashed"/>
            <div class="flex text-sm items-center">
                <span class="color_gray flex-shrink-0">商品类目：</span>
                <ul :class="{ flex: true, 'flex-wrap': true, isDevelop: !data.isDevelop }">
                    <li class="mx-2 cursor-pointer li_hover font-bold px-2 py-1 transition_all" v-for="item in data.categoryList"
                        :key="item.id" @click="categoryQuery(item.id, 'cate')"
                        :style="{ color: item.id === data.cate ? '#019943' : '' }">{{
                            item.name
                        }}
                    </li>
                </ul>
                <el-button link type="primary" @click="data.isDevelop = !data.isDevelop"
                           :icon="data.isDevelop ? 'el-icon-arrow-down' : 'el-icon-arrow-up'">{{
                        data.isDevelop ? "收起" : "展开"
                    }}
                </el-button>
            </div>
        </div>
        <ul class="flex flex-wrap  mt-2  source_of_goods_list" >
<!--            :element-loading-spinner="$otherLoadingSvg"-->
            <li v-for="(item) in data.productList" :key="item.nid"
                :class="{ choice_Border: item.isChoice, source_of_goods: true }">
                <div class="choice" v-if="item.isChoice"></div>
                <div>
                    <el-image :src="item.pictUrl" lazy fit="cover" style="width:100%;height:239px"
                              @click="$jump(`https://market.m.taobao.com/app/ltao-fe/ltao-lite/productdetail.html?id=${item.nid}`)"
                              class="cursor-pointer"></el-image>
                    <div class="p-2"> <p class="text-sm">{{ item.title }}</p>
                        <div>
                            <span class="serviceList">好评率:{{ item.posReviewRate }}</span>
                            <span class="serviceList">发货效率:{{ item.shipRate }}</span>
                            <span class="serviceList">客服响应:{{ item.cstRate }}</span>
                        </div>
                    </div>
                </div>

                <div class="p-2">

                    <div class="flex justify-between items-center mt-2">
                        <span class="cRed text-xs">￥<span class="text-lg font-bold">{{
                                item.reservePrice
                            }}</span></span>
                        <span class="text-xs text-gray-500">{{ item.tejiaTotalSoldQuantity }}</span>
                    </div>
                    <el-row   class="operate">

                    <el-col :span="12"  >
                        <el-button @click="copyValue(item.nid)" size="mini">复制该商品链接</el-button></el-col>
                        <el-col>
                            <el-button type="primary" @click="saveURL(item.nid,1)" size="mini">绑定店铺商品</el-button>
                        </el-col>
                        <!--                    <el-col :span="12" @click="ImmediateShipment(item.nid, data.choiceList)">立即铺货</el-col>-->
                </el-row>
                </div>

            </li>
        </ul>
        <el-dialog
            title="店铺商品"
            :visible.sync="bindingVision"
            width="35%"
            :before-close="handleClose">
            <div class="flex justify-end mb-2">
                <el-input style="width: 30%" v-model="titleSearch" placeholder="请输入商品标题">
                    <el-button slot="append" @click="productListMethods()" icon="el-icon-search"></el-button>
                </el-input>
            </div>
            <div class="flex flex-col justify-center">
                <el-table
                    v-loading="getTableLOading"
                    :data="productList"
                    style="width: 100%"

                    max-height="450px"
                >
<!--                    @row-click="getMessages"-->
                    <template slot="empty">
                        <el-empty></el-empty>
                    </template>
                    <el-table-column
                        align="center"
                        width="130"
                        prop="name"
                        label="本店宝贝信息"
                    >
                        label="本店宝贝信息"
                        <template v-slot:default="scope">
                            <el-popover
                                placement="top-start"
                                width="250"
                                trigger="hover"
                            >
                                <div>
                                    <el-image slot="reference" style="width: 210px;height:210px"
                                              :src="scope.row.img">
                                        <div slot="error" class="image-slot">
                                            <i class="el-icon-picture-outline-round"></i>
                                        </div>
                                    </el-image>
                                </div>
                                <el-image slot="reference"
                                          style="width: 90px; height:90px"
                                          :src="scope.row.img"
                                          fit="fill">

                                    <div slot="error" class="image-slot">
                                        <i class="el-icon-picture-outline-round"></i>
                                    </div>
                                </el-image>

                            </el-popover>

                        </template>
                    </el-table-column>
                    <el-table-column  >
                        <template v-slot:default="scope">
                            <div class=" mr-2">
                                <div class=" flex flex-col mx-2">
                                    <span>{{ scope.row.name }}</span>

                                </div>
                                <div class="flex justify-between">
                                    <span class=" text-red-500">￥{{ scope.row.market_price }}</span>
                                    <div>
                                               <span class="  mr-2">已关联SKU：{{scope.row.bind_sku_count}}/{{scope.row.goods_dis_sku_one_count
                                                   }}</span>
                                        <button @click="getMessages" class="buttonPadding" :class="{'buttonPa': scope.row.status}"
                                                 type="text">
                                            <span>绑定</span>
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </template>
                    </el-table-column>
                </el-table>
                <div class="flex justify-center">
                    <div class=" inputW mt-5 flex justify-center items-center">
                        <el-button type="text" @click="currentPage-=1">
                            <i class="el-icon-arrow-left"></i>
                        </el-button>
                        <input
                            oninput="value=value.replace(/[^\d]/g,'')"
                            type="text"
                            class="getInput"
                            placeholder="请输入内容"
                            v-model="currentPage"/>
                        <span class="px-1">/</span>
                        <span>{{ Math.ceil(productListTotal / limite) }}</span>
                        <el-button @click="currentPage+=1" type="text">
                            <i class="el-icon-arrow-right"></i>
                        </el-button>
                        <span class="text-base">共<span class="mx-1">{{ productListTotal }}</span>条</span>
                    </div>
                </div>
            </div>
        </el-dialog>
        <div class=" py-2 pr-4 bottom_operate border-t border-l border-r border-b-gray-600 bg-white flex flex-wrap items-center fixed bottom-0 justify-center">
<!--            <el-checkbox :indeterminate="data.indeterminate" v-model="data.choiceAll" @change="isChoiceAll">本页全选-->
<!--            </el-checkbox>-->
<!--            <div class="text-sm">（已选{{ data.choiceList.length }}款商品）</div>-->
<!--            <el-button type="primary" size="large" class="mr-4" @click="ImmediateShipment(null, data.choiceList)">-->
<!--                一键铺货（{{-->
<!--                    data.choiceList.length-->
<!--                }}）-->
<!--            </el-button>-->
            <el-pagination :current-page="data.pagination.page" :page-size="data.pagination.limit"
                           :page-sizes="[20, 30, 40, 50]" layout="total, prev, pager, next, jumper"
                           :total="data.pagination.total"
                           @current-change="(val)=> getList(val)" background max-height="300px"/>
        </div>
    </div>
</template>

<script>
import {getBItemPool, getTaoteList} from "@/api/taoteList"
import {mainLoadingSvg} from "@/assets/js/publicSvg.js"
import getProductDetails from "@/utils/sourceGoodsDetails"
import {addLink, disGoodSIndex} from "@/api/department";

export default {
    emits: ["empower"],
    props: {
        isEmpower: Boolean,
        category: {
            type: Array,
            default() {
                return []
            }
        },
    },
    data() {
        return {

            limite:5,
            currentPage:1,
            productUrl:'',
            productList:[],
            productListTotal:0,
            getTableLOading:false,
            titleSearch:'',
            bindingVision:false,
            data: {

                choiceAll: false,
                indeterminate: false,
                choiceList: [],
                pagination: {
                    page: 1,
                    limit: 20,
                    total: 0
                },
                q: "",
                minPrice: "",
                maxPrice: "",
                sort: "popular:des",
                productPool: [{poolId: "", poolName: "全部"}],
                isDevelop: false,
                poolId: "",
                cate: "",
                productList: [],
                categoryList: [{id: "", name: "全部"}]
            },
            sortList: [
                {label: "综合排序", value: "popular:des"},
                {label: "价格降序", value: "price:des"},
                {label: "价格升序", value: "price:asc"},
                {label: "销量降序", value: "sales:des"},
                {label: "客户响应率倒序", value: "cst_reply_rate:des"},
                {label: "客户响应率正序", value: "cst_reply_rate:asc"},
                {label: "好评率倒序", value: "pos_review_all_rate_30d:des"},
                {label: "好评率正序", value: "pos_review_all_rate_30d:asc"},
                {label: "退货率倒序", value: "tj_pinzhi_rfd_rate:des"},
                {label: "退货率正序", value: "tj_pinzhi_rfd_rate:asc"},
                {label: "发货时效倒序", value: "ship_ord_rate_7d_104:des"},
                {label: "发货时效正序", value: "ship_ord_rate_7d_104:asc"}
            ]
        }
    }
    ,
    mounted() {

        this.getList()
        getBItemPool().then(res => {
            this.data.productPool.push(...res.data.model)
        })
        this.productListMethods()
    }
    ,
    methods: {
        getMessages(val) {
            console.log('val',val)
            const data = {
                product_id: val.product_id,
                url: this.productUrl,
            }
            addLink(data).then(res => {
                if (res.code == 1) {
                    this.$message.success(res.msg)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        productListMethods(){
            const data = {
                name:this.titleSearch,
                page: this.titleSearch===''? this.currentPage:'',
                limit: this.limite,
            }
            disGoodSIndex(data).then(res => {
                this.productList=res.data.data
                this.productListTotal=res.data.total
                this.getTableLOading = false
            })
        },
        handleClose() {
            this.bindingVision=false
        },
        saveURL(val,num){
            if(num===1){
                this.productUrl =`https://item.taobao.com/item.htm?id=${val}`
            }
            this.bindingVision=true
        },
        // 立即铺货
        ImmediateShipment(...rest) {
            this.props.isEmpower ? getProductDetails(...rest, "taote") : this.emits("empower")
        }
        ,
        copyValue(id) {
            console.log(id)
            let code=`https://item.taobao.com/item.htm?id=${id}`
            const that=this
            this.$copyText(code).then(
                function () {
                    that.$message({
                        message: "复制成功!",
                        type: 'success'
                    });
                },
                function () {
                    that.$message({
                        message: "复制失败!",
                        type: 'error'
                    });
                }
            );
        },
// 勾选商品
        tick(ind) {
            let {choiceList, productList} = this.data
            let nid = productList[ind].nid
            let index = choiceList .indexOf(nid)
            if (index !== -1) {
                productList[ind].isChoice = false
                choiceList .splice(index, 1)
            } else {
                productList[ind].isChoice = true
                choiceList .push(nid)
            }
        }
        ,
// 类目查询商品
        categoryQuery(id, type) {
            this.data[type] = id
            this.getList(1)
        }
        ,
// 获取商品列表
        getList(curPage) {
            if (curPage) this.data.pagination.page = curPage
            let {q, minPrice, maxPrice, sort, cate, pagination: {page, limit}, poolId} = this.data
            let requestData = {
                q, sort, minPrice, maxPrice, cate, page, limit, poolId
            }
            const loading = this.$loading({
                lock: true,
                text: "查找淘特货源商品。。。",
                background: "rgba(0,0,0,0.8)",
                spinner: mainLoadingSvg
            })
            console.log('requestData',requestData)
            getTaoteList(requestData).then(res => {
                console.log('getTaoteList',res)
                let num = 0
                let {auctions, totalCount} = res.data.result
                this.data.productList = auctions.map(item => {
                    let bool = this.data.choiceList .includes(item.nid)
                    if (bool) num++
                    item.isChoice = bool
                    return item
                })
                this.data.pagination .total = totalCount
                if (num === auctions.length) {
                    this.data.choiceAll  = true
                } else if (num === 0) {
                    this.data.choiceAll  = false
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                loading.close()
            })
        }
        ,
// 是否全选
        isChoiceAll(val) {
            let {choiceList} = this.data()
            this.data.productList.forEach(item => {
                let ind = choiceList .indexOf(item.nid)
                if (val && ind === -1) choiceList .push(item.nid)
                if (!val && ind !== -1) choiceList .splice(ind, 1)
                item.isChoice = val
            })
        }
    }
    ,
    watch: {
        currentPage: {
            handler(val) {
                this.getTableLOading = true
                if (this.productListTotal !== 0) {
                    const num = Math.ceil(this.productListTotal / this.limite)
                    if (val < 1) {
                        this.currentPage = 1
                    } else if (val > num) {
                        this.currentPage = num
                    } else {
                        if (this.timerI != null) {
                            clearTimeout(this.timerI)
                        }
                        this.currentPage = val
                        this.timerI = setTimeout(() => {
                            this.productListMethods()
                        }, 1000)
                    }
                } else {
                    this.currentPage = 1
                }

            }
        },
        productList: {
            // 监听产品列表数据，从而改变全选框状态
            handler(oldVal, newVal) {
                console.log(oldVal, newVal)
                // let {choiceAll, indeterminate} = this.data
                let choiceLen = newVal.filter(item => item.isChoice).length
                if (choiceLen === 0) {
                    this.data.choiceAll  = false
                    this.data.indeterminate  = false
                } else if (choiceLen === newVal.length) {
                    this.data.choiceAll  = true
                    this.data.indeterminate  = false
                } else {
                    this.data.indeterminate  = true
                }
            }
            ,
            immediate: true
        }
        ,
        category: {
            handler(olVal, newVal) {
                console.log('olVal',olVal, 'newVal',newVal)
                if (olVal.length) this.data.categoryList.push(...olVal)
            }
            ,
            immediate: true
        }
    }
    ,


}


// }

// watch(() =>this.props.category, newVal => {
//   if (newVal.length) this.data.categoryList.push(...newVal)
// }, { deep: true })
// // 监听产品列表数据，从而改变全选框状态
// watch(() => data.productList, newVal => {
//
// }, { deep: true })


</script>

<style lang="scss" scoped>
$cF3: #fff;
$bodyColor: #019943;
$cRed:#ff3600;
$cF3: #fff;

::v-deep .el-input-group__append {
    box-shadow: none;
    background-color: $bodyColor;
    color: $cF3;
}
.title {
    background-image: linear-gradient(180deg, $bodyColor, #e2d211);
    -webkit-background-clip: text;
    color: transparent;
}
.cRed {
    color: $cRed;
}
.bgGOU{
background: linear-gradient(180deg, #e9feec, #edffea, #f7f8fd, #fff,#fff)
}
.source_of_goods {
    width:252px;
    margin: 0 5px 10px 5px;


    .serviceList {
        background-image: linear-gradient(to right,#f0a664,#d48336);
        //background: ;//
        color: $cF3;
        border-radius: 5px;
        display: inline-block;
        font-size: 11px;
        padding: 0 3px;
        margin: 0 3px 1px 0;
    }

    .operate {
        display: flex;
        //border-top: 1px solid #ccc;

        .el-col:first-child {
            //border-right: 1px solid #ccc;
        }

        .el-col {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 0;
            cursor: pointer;
            font-size: 14px;
            &:hover{
                color: $bodyColor;
            }
        }
    }

    background-color: $cF3;
    //border: 2px solid rgb(225, 225, 225);
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.2s;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &:hover {
        box-shadow: 0 0 8px 4px rgb(220, 220, 220);
    }

    .choice {
        z-index: 10;
        position: absolute;
        top: 0;
        right: 0;
        border: 23px solid transparent;
        border-color: $bodyColor $bodyColor transparent transparent;
        &::after{
            content: "✓";
            position: absolute;
            top: -27px;
            right: -20px;
            color: $cF3;
            font-size: 24px;
            font-weight: bold;
        }
    }
}
.serviceList {
    background: #ff6571;
    color: $cF3;
    border-radius: 20px;
    display: inline-block;
    font-size: 12px;
    padding: 2px 8px;
    margin: 0 3px 3px 0;
}


.bottom_operate{
    background-color: rgba(248, 244, 244, 0.95);
    right: 20%;
    left: 20%;
    border-radius:1rem 1rem 0 0;
    padding: 5px 0;
}
//.source_of_goods{
//    min-width:288px ;
//    width: 20%;
//    padding: 10px;
//}

.el-divider--horizontal {
    margin: 2px 0;
}

.isDevelop {
    overflow: hidden;
    height: 30px;
}

.li_hover:hover {
    color: #3949FAFF;
}

.transition_all {
    transition: all 0.2s linear;
}
</style>