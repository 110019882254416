<template>
    <!--    <div>111111111</div>-->
    <div>
        <div class="card">
            <div class="bgLinear flex  justify-between p-3 items-center">
                <h3 class="text-2xl font-bold body_color  title">1688货源</h3>
                <!--              mb-2  text-center-->
                <p class="">
                    <!--                    text-center-->
                    <el-input v-model="data.keywords" placeholder="请输入关键词进行搜索" maxlength="3" show-word-limit clearable
                              style="max-width:600px">
                        <template #append>
                            <el-button @click="getList(1)">
                                <el-icon>
                                    <Search/>
                                </el-icon>&nbsp;搜索
                            </el-button>
                        </template>
                    </el-input>
                </p>
                <!--                <el-divider border-style="dashed"/>-->
            </div>
            <div class="flex px-3 items-center">
                <ul :class="{ flex: true, 'flex-wrap': true, isDevelop: !data.isDevelop }">
                    <li
                        :class="{ productType: true, 'px-3': true, 'py-1': true,'font-semibold':true, 'mx-1': true, 'text-sm': true, 'cursor-pointer': true, activated: data.activated === index }"
                        @click="classifyQuery(index)" v-for="(item, index) in data.typeList" :key="item.id">{{
                            item.name
                        }}
                    </li>
                </ul>
                <el-button link type="primary" @click="data.isDevelop = !data.isDevelop"
                           :icon="data.isDevelop ? 'el-icon-arrow-down' : 'el-icon-arrow-up'">
                    {{
                        data.isDevelop ? "收起" : "展开"
                    }}
                </el-button>
            </div>
            <el-divider border-style="dashed"/>
            <div class="px-4">
                <el-checkbox v-model="item.isChoice" @change="val => changeType(val, index, item.ruleId)"
                             v-for="(item, index) in data.synthesizeType" :key="item.ruleId">
                    <div style="color: #d48336">{{ item.ruleName }}
                    </div>
                </el-checkbox>
            </div>
        </div>
        <div class="mt-2">
            <ul class="flex flex-wrap mb-24 source_of_goods_list">
                <!--                :element-loading-spinner="$otherLoadingSvg"-->
                <li v-for="(item) in data.productList" :key="item.itemId"
                    :class="{ choice_Border: item.isChoice, source_of_goods: true }">
                    <div class="choice" v-if="item.isChoice"></div>

                    <div>
                        <el-image :src="item.imgUrl" fit="fill" lazy style="width:100%;height: 239px;"
                                  @click="$jump(`https://detail.1688.com/offer/${item.itemId}.html`)"
                                  class="cursor-pointer"></el-image>
                        <div class="p-2">
                            <p class="text-sm">{{ item.title }}</p>
                            <div>
                                <span class="serviceList" v-for="el in item.serviceList" :key="el.code">{{
                                        el.name
                                    }}</span>
                            </div>
                        </div>

                    </div>

                    <div class="p-2">


                        <div class="flex justify-between items-center mt-2">
              <span class="cRed text-xs">￥<span class="text-2xl font-bold">{{
                      (item.minPrice / 100).toFixed(2)
                  }}</span>-￥<span class="text-lg font-bold">{{ (item.maxPrice / 100).toFixed(2) }}</span></span>
                            <span class="text-xs text-gray-500">已售{{ resetAalesVolume(item.salesCnt90d) }}</span>
                        </div>
                        <el-row class="operate  ">
                            <el-col :span="12">
                                <el-button @click="copyValue(item.itemId)" size="mini">复制该商品链接</el-button>

                            </el-col>
                            <el-col>
                                <el-button type="primary" @click="saveURL(item.itemId,1)" size="mini">绑定店铺商品</el-button>
                            </el-col>
                            <!--                        <el-col :span="12" @click="ImmediateShipment(item.itemId, data.choiceList)">立即铺货</el-col>-->
                        </el-row>
                    </div>

                </li>
            </ul>
            <el-dialog
                title="店铺商品"
                :visible.sync="bindingVision"
                width="35%"
                :before-close="handleClose">
                <div class="flex justify-end mb-2">
                    <el-input style="width: 30%" v-model="titleSearch" placeholder="请输入商品标题">
                        <el-button slot="append" @click="productListMethods()" icon="el-icon-search"></el-button>
                    </el-input>
                </div>
                <div class="flex flex-col justify-center">
                    <el-table
                        v-loading="getTableLOading"
                        :data="productList"
                        style="width: 100%"

                        max-height="450px"
                    >
                    <!--                        @row-click="getMessages"-->
                        <template slot="empty">
                            <el-empty></el-empty>
                        </template>
                        <el-table-column
                            align="center"
                            width="130"
                            prop="name"
                            label="本店宝贝信息"
                        >
                            label="本店宝贝信息"
                            <template v-slot:default="scope">
                                <el-popover
                                    placement="top-start"
                                    width="250"
                                    trigger="hover"
                                >
                                    <div>
                                        <el-image slot="reference" style="width: 210px;height:210px"
                                                  :src="scope.row.img">
                                            <div slot="error" class="image-slot">
                                                <i class="el-icon-picture-outline-round"></i>
                                            </div>
                                        </el-image>
                                    </div>
                                    <el-image slot="reference"
                                              style="width: 90px; height:90px"
                                              :src="scope.row.img"
                                              fit="fill">

                                        <div slot="error" class="image-slot">
                                            <i class="el-icon-picture-outline-round"></i>
                                        </div>
                                    </el-image>

                                </el-popover>

                            </template>
                        </el-table-column>
                        <el-table-column  >
                            <template v-slot:default="scope">
                                <div class=" mr-2">
                                    <div class=" flex flex-col mx-2">
                                        <span>{{ scope.row.name }}</span>

                                    </div>
                                    <div class="flex justify-between">
                                        <span class=" text-red-500">￥{{ scope.row.market_price }}</span>
                                        <div>
                                               <span class="  mr-2">已关联SKU：{{scope.row.bind_sku_count}}/{{scope.row.goods_dis_sku_one_count
                                                   }}</span>
                                            <button @click="getMessages" class="buttonPadding" :class="{'buttonPa': scope.row.status}"
                                                     type="text">
                                                <span>绑定</span>
                                            </button>
                                        </div>

                                    </div>
                                </div>

                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="flex justify-center">
                    <div class=" inputW mt-5 flex justify-center items-center">
                        <el-button type="text" @click="currentPage-=1">
                            <i class="el-icon-arrow-left"></i>
                        </el-button>
                        <input
                            oninput="value=value.replace(/[^\d]/g,'')"
                            type="text"
                            class="getInput"
                            placeholder="请输入内容"
                            v-model="currentPage"/>
                        <span class="px-1">/</span>
                        <span>{{ Math.ceil(productListTotal / limite) }}</span>
                        <el-button @click="currentPage+=1" type="text">
                            <i class="el-icon-arrow-right"></i>
                        </el-button>
                        <span class="text-base">共<span class="mx-1">{{ productListTotal }}</span>条</span>
                    </div>
                </div>
                </div>
            </el-dialog>
            <div
                class=" bottom_operate border-t border-l border-r border-b-gray-600 bg-white  flex flex-wrap items-center fixed bottom-0 justify-center">
                <!--                <el-checkbox :indeterminate="data.indeterminate" v-model="data.choiceAll" @change="isChoiceAll">本页全选</el-checkbox>-->
                <!--                <div class="text-sm">（已选{{ data.choiceList.length }}款商品）</div>-->
                <!--                <el-button type="primary" size="large" class="mr-4" @click="ImmediateShipment(null, data.choiceList)">一键铺货（{{-->
                <!--                        data.choiceList.length-->
                <!--                    }}）-->
                <!--                </el-button>-->
                <el-pagination :current-page="data.pagination.page" :page-size="data.pagination.limit"
                               :page-sizes="[20, 30, 40, 50]" layout="total, prev, pager, next, jumper"
                               :total="data.pagination.total"
                               @current-change="val => getList(val)" background max-height="300px"/>
            </div>
        </div>
        <el-dialog v-model="data.dialogVisible" title="授权提示" width="500">
            <div>
                <p>因跨平台无法获取到货源信息，请先完成1688授权</p>
                <p class="my-3">第1步：请点击下方“立即授权”按钮，开始授权</p>
                <p>第2步：授权完成后，点击“已授权，立即铺货”，获取数据</p>
            </div>
            <template #footer>
                <div class="flex justify-center">
                    <el-button @click="empowerAt1688" type="primary">
                        {{ data.isJumpEmpowerUrl ? "已授权，立即铺货" : "立即授权" }}
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {productType, productList} from "@/api/1688List";
import {mainLoadingSvg} from "@/assets/js/publicSvg.js"
import {addLink, disGoodSIndex} from "@/api/department";
// import getProductDetails from "@/utils/sourceGoodsDetails"

export default {
    data() {
        return {

            titleSearch:'',
            productUrl:'',
            limite:5,
            currentPage:1,
            getTableLOading:false,
            bindingVision:false,
            productList:[],
            productListTotal:0,
            data: {
                typeList: [{id: "", name: "全部"}],
                isDevelop: false,
                pagination: {
                    page: 1,
                    limit: 20,
                    total: 0
                },

                activated: 0,
                productList: [],
                choiceAll: false,
                synthesizeType: [],
                typeIndex: null,
                keywords: "",
                choiceList: [],
                indeterminate: false,
                dialogVisible: false,
                empower1688Url: "",
                isJumpEmpowerUrl: false,
                isEmpower: false,

            }
        }
    },
    watch: {
        currentPage: {
            handler(val) {
                this.getTableLOading = true
                if (this.productListTotal !== 0) {
                    const num = Math.ceil(this.productListTotal / this.limite)
                    if (val < 1) {
                        this.currentPage = 1
                    } else if (val > num) {
                        this.currentPage = num
                    } else {
                        if (this.timerI != null) {
                            clearTimeout(this.timerI)
                        }
                        this.currentPage = val
                        this.timerI = setTimeout(() => {
                            this.productListMethods()
                        }, 1000)
                    }
                } else {
                    this.currentPage = 1
                }

            }
        },
        // 监听产品列表数据，从而改变全选框状态
        'data.productList': {
            handler(old, newVal) {
                // console.log('old',old,'newVal', newVal)

                let choiceLen = old.filter(item => item.isChoice).length
                if (choiceLen === 0) {
                    this.data.choiceAll = false
                    this.data.indeterminate = false
                } else if (choiceLen === newVal.length) {
                    this.data.choiceAll = true
                    this.data.indeterminate = false
                } else {
                    this.data.indeterminate = true
                }
            }, immediate: true
        }
    },
    mounted() {

        // 获取分类
        productType({status: 1}).then(res => {
            let {category, tag} = res.data
            this.data.typeList.push(...category)
            this.data.synthesizeType = tag.map(item => {
                item.isChoice = false
                return item
            })
            this.getList()
        })
        this.productListMethods()
    },
    methods: {
        saveURL(val,num){
            if(num===1){
                this.productUrl = `https://detail.1688.com/offer/${val}.html`
            }
            this.bindingVision=true
        },
        getMessages(val) {
            const data = {
                product_id: val.product_id,
                url: this.productUrl,
            }
             addLink(data).then(res => {
                if (res.code == 1) {
                    this.$message.success(res.msg)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        productListMethods(){
            const data = {
                name:this.titleSearch,
                page: this.titleSearch===''?this.currentPage:'',
                limit: this.limite,
            }
            disGoodSIndex(data).then(res => {
                this.productList=res.data.data
                this.productListTotal=res.data.total
                this.getTableLOading = false
            })
        },
        handleClose() {
            this.bindingVision=false
        },
        // 1688授权
        empowerAt1688() {
            if (this.isJumpEmpowerUrl) {
                this.data.dialogVisible = false
                this.data.isJumpEmpowerUrl = false
                this.data.empower1688Url = ""
            } else {
                window.open(this.data.empower1688Url)
                this.data.isJumpEmpowerUrl = true
            }
        },
        copyValue(id) {

            let code = `https://detail.1688.com/offer/${id}.html`
            const that = this
            this.$copyText(code).then(
                function () {
                    that.$message({
                        message: "复制成功!",
                        type: 'success'
                    });
                },
                function () {
                    that.$message({
                        message: "复制失败!",
                        type: 'error'
                    });
                }
            );
        },
// 勾选商品,
        tick() {
            //  let {productList,choiceList}=this.data
            // let itemId =productList[ind].itemId
            // let index = choiceList.indexOf(itemId)
            // if (index !== -1) {
            //     this.data.productList[ind].isChoice = false
            //     this.data.choiceList  .splice(index, 1)
            // } else {
            //     this.data.productList[ind].isChoice = true
            //     this.data.choiceList  .push(itemId)
            // }
        },
// 立即铺货
//          ImmediateShipment  (...rest)  {
//             if (this.data.isEmpower  ) {
//                 getProductDetails(...rest, "1688")
//             } else {
//                 // 查看是否授权
//                 checkIsEmpower().then(() => {
//                     this.data.isEmpower   = true
//                     getProductDetails(...rest, "1688")
//                 }).catch(err => {
//                     let {code, data} = err
//                     if (code === 5) {
//                         this.data.dialogVisible   = true
//                         this.data.empower1688Url   = data
//                     }
//                 })
//             }
//         },
// 处理商品销量
        resetAalesVolume(val) {
            const len = val?.toString().length
            const type = {
                3: parseInt(val / 100) * 100 + "+",
                4: parseInt(val / 1000) * 1000 + "+",
                5: parseInt(val / 10000) + "万+"
            }
            return len >= 3 ? type[len >= 5 ? 5 : len] : (val || 0)
        },
// 商品标签筛选商品
        changeType(val, index) {
            let {typeIndex} = this.data
            if (val) {
                if (typeIndex !== null) this.data.synthesizeType[typeIndex].isChoice = false
                this.data.typeIndex = index
                this.getList(1)
            } else {
                this.data.typeIndex = null
            }
        },
// 是否全选
        isChoiceAll(val) {
            let {productList, choiceList} = this.data
            productList.forEach(item => {
                let ind = choiceList.indexOf(item.itemId)
                if (val && ind === -1) choiceList.push(item.itemId)
                if (!val && ind !== -1) choiceList.splice(ind, 1)
                item.isChoice = val
            })
        },
// 查询商品
        classifyQuery(ind) {
            this.data.activated = ind
            this.getList(1)
        },
// 获取商品列表
        getList(curPage = null) {
            const loading = this.$loading({
                lock: true,
                text: "查找1688货源商品。。。",
                background: "rgba(0,0,0,0.8)",
                spinner: mainLoadingSvg
            })
            if (!curPage == null) this.data.pagination.page = curPage
            let {page, limit} = this.data.pagination
            let {typeList, activated, keywords, synthesizeType, typeIndex} = this.data
            productList({
                page,
                limit,
                categoryId: typeList[activated].id,
                keywords: keywords,
                ruleId: synthesizeType[typeIndex]?.ruleId
            }).then(res => {
                let num = 0
                let {rows, total} = res.data
                this.data.productList = rows.map(item => {
                    let bool = this.data.choiceList.includes(item.itemId)
                    if (bool) num++
                    item.isChoice = bool
                    return item
                })
                this.data.pagination.total = total
                if (num === rows.length) {
                    this.data.choiceAll = true
                } else if (num === 0) {
                    this.data.choiceAll = false
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                loading.close()
            })
        }
    }
}

</script>

<style lang="scss" scoped>
$bodyColor: #019943;
$cRed: #ff3600;
$cF3: #fff;
.cRed {
    color: $cRed;
}

.source_of_goods {
    width: 252px;
    margin: 0 5px 10px 5px;


    .serviceList {
        background-image: linear-gradient(to right, #f0a664, #d48336);
        //background: ;//
        color: $cF3;
        border-radius: 5px;
        display: inline-block;
        font-size: 11px;
        padding: 0 3px;
        margin: 0 3px 1px 0;
    }

    .operate {
        display: flex;
        //border-top: 1px solid #ccc;

        .el-col:first-child {
            //border-right: 1px solid #ccc;
        }

        .el-col {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 0;
            cursor: pointer;
            font-size: 14px;

            &:hover {
                color: $bodyColor;
            }
        }
    }

    background-color: $cF3;
    //border: 2px solid rgb(225, 225, 225);
    border-radius: 10px;
    overflow: hidden;
    transition: all 0.2s;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //justify-content: flex-start;

    &:hover {
        box-shadow: 0 0 8px 4px rgb(220, 220, 220);
    }

    .choice {
        z-index: 10;
        position: absolute;
        top: 0;
        right: 0;
        border: 23px solid transparent;
        border-color: $bodyColor $bodyColor transparent transparent;

        &::after {
            content: "✓";
            position: absolute;
            top: -27px;
            right: -20px;
            color: $cF3;
            font-size: 24px;
            font-weight: bold;
        }
    }
}

.serviceList {
    background: #ff6571;
    color: $cF3;
    border-radius: 20px;
    display: inline-block;
    font-size: 12px;
    padding: 2px 8px;
    margin: 0 3px 3px 0;
}

.bottom_operate {
    background-color: rgba(248, 244, 244, 0.95);
    right: 20%;
    left: 20%;
    border-radius: 1rem 1rem 0 0;
    padding: 5px 0;
}

//.source_of_goods{
//    min-width:288px ;
//    width: 20%;
//    padding: 10px;
//}

::v-deep .el-dialog__header {
    border-bottom: 1px solid #ccc;
    margin: 0;
}

::v-deep .el-dialog__footer {
    border-top: 1px solid #ccc;
}

::v-deep .el-dialog__body {
    padding: 20px;
}

::v-deep .el-input-group__append {
    box-shadow: none;
    background-color: $bodyColor;
    color: $cF3;
}

.card {
    background-color: $cF3;
    border-radius: 5px;
    padding-bottom: 10px;
    overflow: hidden;

    .bgLinear {
        background: linear-gradient(180deg, #e9feec, #edffea, #f7f8fd, $cF3);
        padding-top: 20px;
    }
}

.title {
    background-image: linear-gradient(180deg, $bodyColor, #e2d211);
    -webkit-background-clip: text;
    color: transparent;
}

::v-deep .el-divider--horizontal {
    margin: 6px 0
}

.isDevelop {
    overflow: hidden;
    height: 30px;
}

.productType {
    border-radius: 5px;

    &:hover {
        color: $cF3;
        background-color: $bodyColor;
        transition: all 0.2s linear;
    }
}

.activated {
    background-color: $bodyColor;
    color: $cF3;
}
</style>