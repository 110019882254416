import axios from "axios";
import {Message} from "element-ui";
//
// let requestCount = 0;
// let LoadingInstance = null;

const Service = axios.create({
    timeout: 1000 * 1000000,
    withCredentials: true,
    BASE_URL: '/api',
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
})

// 请求拦截
Service.interceptors.request.use(function (config) {
    //在每次的请求中添加token
    // dyguanjiaapi.niuniucrm.com
    //http://192.168.2.10/
    //https://dyguanjiaapi.niuniucrm.com/
    // if (requestCount == 0) {
    //     LoadingInstance = Loading.service({
    //         lock: true,
    //         // target: document.querySelector('.base-popup-content'),
    //         text: '加载中...',
    //         background: 'rgba(0, 0, 0, 0.8)'
    //     })
    // }
    // requestCount += 1;
    config.url = 'https://dyfxdfapi.niuniucrm.com/' + config.url
    //设置请求头U0kYFzqMPGIy3uKQVDalBemNA1RcxCv6
    // jK9kRPWbfU0x12nhQJT3dpGc7Do4MSNYcoi
    //
    config.headers = {
        'token': localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    }
    return config;


}, error => {
    // requestCount -= 1;
    // //当加载实例存在且计数小于等于0的时候把加载效果关闭
    // if (requestCount <= 0 && LoadingInstance) {
    //     LoadingInstance.close();
    //     requestCount = 0;
    // }
    return Promise.reject(error);

})

// 响应拦截

Service.interceptors.response.use(response => {
    //当请求返回的时候计数-1
    // requestCount -= 1;
    // //根据返回不同的状态码做不同的事情
    // if (requestCount <= 0 && LoadingInstance) {
    //     LoadingInstance.close();
    //     requestCount = 0;
    // }
    if (response.status) {
        switch (response.status) {
            case 200:
                return response.data;
            case 401:
                //未登录处理方法
                Message.error(response.data.msg);
                break;
            case 403:
                //token过期处理方法
                break;
            default:
                Message.error(response.data.msg)
        }
    } else {
        return response;
    }
}, function (error) {
    // requestCount -= 1;
    // //根据返回不同的状态码做不同的事情
    // if (requestCount <= 0 && LoadingInstance) {
    //     LoadingInstance.close();
    //     requestCount = 0;
    // }
    if(error&&error.response){
        switch (error.response.status) {
            case 401:
                //未登录处理方法
                Message.error("token失效,请重新授权")
                window.location.href = error.response.data.data.url
                break;
            default:
                Message.error(error.response.data.msg)
        }
    }else {
        //超时处理
        if(JSON.stringify(error).includes('timeout')){
            Message.error("请求超时,请重试")
        }
        error.message = "服务器响应超时"
    }
    return Promise.resolve(error.response);
    // return Promise.reject(error);
})
export default Service