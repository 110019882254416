import Vue from 'vue'
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import VueRouter from 'vue-router'
import gotHome from '../views/gotHome.vue'
import addressVue from '../components/addressVue.vue'
import personalProfile from "@/message/PersonalProfile";
import homePage from '../components/homePage.vue'
import StoreManagement from "@/message/StoreManagement";
import orderComponent from '../components/orderComponent.vue'
import RefundRequest from '../components/RefundRequest.vue'
import orderStatus from '../components/orderStatus.vue'
import DistributionGoods from '../StoreManagements/DistributionGoods.vue'
import SetOrderingParameter from '../StoreManagements/SetOrderingParameter.vue'
import ProfitReport from '../StoreManagements/ProfitReport.vue'
import OrderRecordQ from '../StoreManagements/OrderRecordQ.vue'
import PurchaseAccount from '../StoreManagements/PurchaseAccount.vue'
import MySubscription from '../message/MySubscription.vue'
import taoTe from "@/StoreManagements/taoTe";
import jumpGet from "@/views/jumpGet";
import GETpROM from "@/components/GETpROM";
import Goods_88List from "@/views/SourceCenter/1688GoodsList"
import taoGoodsList from "@/views/SourceCenter/taoteGoodsList"
NProgress.configure({
    showSpinner: false
})
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push
// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err)
}

// // 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
Vue.use(VueRouter)
const routes = [
    {
        path: "/",
        component: jumpGet,
    },

    {
        path: '/gotHome',
        component: gotHome,
        children: [
            {
                path: "/GETpROM",
                component: GETpROM
            },
            {
                name: 'PurchaseAccount',
                path: '/PurchaseAccount',
                component: PurchaseAccount,
            },
          {
                name: '1688GoodsList',
                path: '/1688GoodsList',
                component: Goods_88List,
            }, {
                name: 'taoteGoodsList',
                path: '/taoteGoodsList',
                component:taoGoodsList,
            },
            {
                path: '/MySubscription',
                component: MySubscription
            },
            {
                path: '/addressVue',
                component: addressVue,
            },
            {
                path: '/OrderRecordQ',
                component: OrderRecordQ,
            },
            {
                path: '/RefundRequest',
                component: RefundRequest,
            },
            {
                path: '/orderStatus',
                component: orderStatus,
            },
            {
                path: '/SetOrderingParameter',
                component: SetOrderingParameter,
            },
            {
                path: '/ProfitReport',
                component: ProfitReport
            },
            {
                path: '/DistributionGoods',
                component: DistributionGoods,
            },
            {
                path: '/orderComponent',
                component: orderComponent,
            },
            {path: '/', redirect: '/homePage'},
            {
                path: "/homePage",
                component: homePage,
            },
            {
                path: "/storeManagement",
                component: StoreManagement,
            },
            {
                path: '/personalProfile',
                component: personalProfile,

            }

        ],

    },
    {
        name: 'taoTe',
        path: '/taoTe',
        component: taoTe
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    routes
})
//前置守卫
router.beforeEach((to, from, next) => {
    NProgress.start();//开启进度条
    next();
    // let loadingInstance1 = this.$loading({ fullscreen: true });
    //  this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
    //    loadingInstance1.close();
    //  });
})
// //后置守卫
router.afterEach(() => {
    NProgress.done()
    // let loadingInstance1 = this.$loading({ fullscreen: false });
    // this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
    //   loadingInstance1.close();
    // });
})
export default router
