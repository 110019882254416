<script>
import {bindPurchaseAccount, getBindPurAccount} from "@/api/department";

export default {
    name: 'taoTe',
    data() {
        return {
            getHidden: false,
            loseEfficacy: false,
            active: 0,
            index: 0,
            data: {},
            timer: null,
            tempCode: this.$route.query.tempCode,
            url: 'data:image/jpeg;base64,' + this.$route.query.url
        }
    },
    mounted() {
        this.getStatus()
        this.$once('hook:beforeDestroy', () => {
            clearInterval(this.timer)
        })
    },
    methods: {
        next() {
            if (this.active++ > 2) this.active = 0;
        },
        getStatus() {
            this.timer = setInterval(() => {
                this.index += 1
                const data = {tempCode: this.tempCode}
                getBindPurAccount(data).then(res => {
                    if (res.code == 1) {
                        clearInterval(this.timer)
                        this.getHidden = true
                        this.data = res.data
                        this.next()
                        this.$message.success(res.msg+"3秒后自动跳转回采购账号管理页面")
                        setTimeout(() => {
                            this.getJUmp()
                        },3000)


                    }
                })
                if (this.index * 5 >= 120) {
                    clearInterval(this.timer)
                    this.loseEfficacy = true
                }
            }, 5000)

        },
        rEget() {
            this.getHidden=false
            this.loseEfficacy = false
            const data =
                {
                    sourceTypeId: 3
                }
            bindPurchaseAccount(data).then(res => {
                if (res.code == 101) {
                    this.tempCode = res.data.tempCode
                    this.url = res.data.url
                }
                this.getStatus()
            })
        },
        getJUmp() {
            this.next()
            this.$router.replace({
                path: '/PurchaseAccount'
            })
        }

    }
}
</script>
<template>
    <div class="p-4">
        <div class="">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/PurchaseAccount' }">返回</el-breadcrumb-item>
                <el-breadcrumb-item>淘特授权</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="p-4">
            <span class="font-bold text-black text-2xl">牛牛分销下单工具页</span>
            <div class="p-4 w-3/4 wMin">
                <el-steps :active="active" finish-status="success" >
                    <el-step title="使用淘特APP扫码">
                        <div  slot="description">
                            <div class="posiR">
                                <el-image style="width: 300px;height: 300px;" :src="url">
                                </el-image>
                            </div>
                            <div v-if="loseEfficacy==true" class=" posiBg">
                                <span>已失效</span>
                                <el-button @click="rEget" size="mini">重新获取</el-button>
                            </div>
                        </div>

                    </el-step>
                    <el-step title="确认已扫码授权" >
                        <div  slot="description">
                            <div v-if="getHidden==true" class="flex-col items-start">
                                <div>
                                    <span>账号：{{ data.account }}</span>
                                </div>
                                <div>
                                    <span>店铺：{{ data.shop_name }}</span>
                                </div>
                                <div>
                                    <span>所属平台：{{ data.source_name }}</span>
                                </div>
                                <div>
                                    <el-button @click="getJUmp">
                                        确认
                                    </el-button>
                                </div>

                            </div>
                        </div>
                    </el-step>
                </el-steps>
            </div>
        </div>

    </div>
</template>
<style>
.el-step__description{
    width: 100px!important;
}
.wMin {
    min-width: 300px;
}

.posiR {
    width: 300px;
    height: 300px;
    position: relative;
}

.posiBg .el-button--mini {
    padding: 2px !important;
}

.posiBg {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    bottom: 0;
    width: 300px;
    height: 300px;
    background: rgba(103, 92, 92, 0.5);
    position: absolute;
}
</style>