// import Service from "./request";
import {post} from "./index"

//登录
export const login = data => post('/api/Shop/login', data)
//同步退货地址
export const getAddressList = data => post('/api/ShopAddress/getAddressList', data)
//获取地址列表数据
export const AddressIndex = data => post('/api/ShopAddress/index', data)
//获取个人信息
export const userData = data => post('/api/Shop/index', data)
//获取省数据
export const getProvince = data => post('/api/ShopAddress/getProvince', data)
//根据省id获取省下面的地区数据
export const getAreasByProvince = data => post('/api/ShopAddress/getAreasByProvince', data)
//创建地址
export const addShopAddress = data => post('/api/ShopAddress/addShopAddress', data)
//点击编辑获取详情
export const getAddressDetail = data => post('/api/ShopAddress/getAddressInfo', data)
//修改提交
export const editShopAddress = data => post('/api/ShopAddress/editShopAddress', data)
//订单查询
export const getOrderList = data => post('/api/Order/index', data)
//订单状态数量
export const getOrderStatusNum = data => post('/api/Order/orderNum', data)
//获取采购时间和退款原因
export const getReason = data => post('/api/BaseSet/getBase',data)
 //下单参数设置
export const getBase = data => post('/api/BaseSet/index', data)
//获取采购时间和退款原因  /api/BaseSet/getBase
//分销商品列表/api/Goods/disGoodSIndex
export const disGoodSIndex = data => post('/api/Goods/disGoodSIndex', data)
//货源绑定
export const goodLink = data => post('/api/Goods/bindLink', data)
//加入关联
export const addLink = data => post('/api/Goods/saveUrlLink', data)
//sku新增
export const addSku = data => post('/api/Goods/addGoodsBindSku', data)
//货源同步
export const goodsSync = data => post('/api/Goods/sourceSyn', data)
//货源保存
export const goodsSave = data => post('/api/Goods/saveGoodsBindUrl', data)
//sku删除
export const delSku = data => post('/api/Goods/delGoodsBindSku', data)
//sku修改
export const editSku = data => post('/api/Goods/editGoodsBindSku', data)
//采购账号
export const getPurchaseAccount = data => post('/api/SourceType/index', data)
//绑定采购账号
export const bindPurchaseAccount = data => post('/api/PurAccount/bindPurAccount', data)
//轮询查询是否绑定陶特账号
export const getBindPurAccount = data => post('/api/PurAccount/polling', data)
//获取采购账号
export const getPurAccount = data => post('/api/PurAccount/index', data)
//删除采购账号
export const delPurAccount = data => post('/api/PurAccount/deletePurAccount', data)
//下单参数设置数据提交
export const getBaseSet = data => post('/api/BaseSet/saveAndEdit', data)
//获取分销订单列表数据
export const disOrderList = data => post('/api/DisOrder/index', data)
//订单数据同步
export const disOrderSyn = data => post('/api/Order/synOrder', data)
//立即下单
export const orderNow = data => post('/api/DisOrder/doDisOrderOne', data)
//根据图片搜淘特同款
export const getGoodsList = data => post('/api/DisOrder/taoTeSearch', data)
//获取陶特商品详情
export const getGoodsDetail = data => post('/api/DisOrder/getTaoTeGoodsInfo', data)
//采购下单
export const purchaseOrder = data => post('/api/DisOrder/placeAnOrderOne', data)
//根据商品详情地址获取商品sku信息（搜索）
export const getSkuInfo = data => post('/api/DisOrder/searchForUrl', data)
//取消采购
export const cancelOrder = data => post('/api/DisOrder/cancelDisOrder', data)
//单个支付
export const payOrder = data => post('/api/DisOrder/onePay', data)
//查看物流
export const getLogistics = data => post('/api/DisOrder/lookLogistics', data)
//1688退款原因
export const getReason1688 = data => post('/api/DisOrder/applyReasonId',data)
//1688上传图片
export const uploadImg1688 = data => post('/api/DisOrder/uploadReasonImg',data)
//退款申请
export const refundOrder = data => post('/api/DisOrder/requestRefund', data)
//分销采购total
export const disOrderTotal = data => post('/api/DisOrder/orderCount', data)
//退款订单列表
export const refundIndexList = data => post('/api/DisOrder/refundIndex', data)
//获取各个申请退款数量
export const refundCount = data => post('/api/DisOrder/refundOrderCount', data)
//退款订单同步
export const refundSyn = data => post('/api/Order/synRefundOrder', data)
//退款详情
export const refundDetail = data => post('/api/DisOrder/lookRefund', data)
//商品同步
export const goodsSyn = data => post('/api/Goods/synGoods', data)
//获取淘特退款类型
export const getRefundType = data => post('/api/DisOrder/getTaoTeRefundApplyType', data)
//陶特申请退款
export const taoTeRefund = data => post('/api/DisOrder/taoTeRefund', data)
//货源删除
export const delGoods = data => post('/api/Goods/delGoodsBindUrl', data)
//货源设置或者取消默认
export const setGoods = data => post('/api/Goods/setDefault', data)
//sku设置或者取消默认
export const setSku = data => post('/api/Goods/skuDefault', data)
//淘特获取可退款类型2
export const getRefundType2 = data => post('/api/DisOrder/refundApplyInitTaoTe', data)
//淘特退款3
export const taoTeRefund3 = data => post('/api/DisOrder/taoTeRefund', data)
//获取所有商品
export const getGoods = data => post('/api/Goods/index', data)
//选择商品
export const selectGoods = data => post('/api/Goods/selectAndCancel', data)
//获取已选中商品
export const getSelectGoods = data => post('/api/Goods/selectGoods', data)
//订单数量数据
export const orderCount = data => post('/api/Shop/mainOrder', data)
//折线图数据
export const lineChart = data => post('/api/Shop/foldLineData', data)
//最近待采购订单
export const orderList = data => post('/api/Shop/recentlyOrder', data)
//开启或关闭陶特免密支付
export const freePaymentForTao = data => post('/api/PurAccount/freePaymentForTaoTe', data)
//开启或关闭1688免密支付
export const freePaymentForAli = data => post('/api/PurAccount/freePaymentForAliBaBa', data)
//轮询查询是否开启1688免密支付
export const pollingAli = data => post('/api/PurAccount/pollingSearchForAliBaBa', data)
//轮询查询是否开启陶特免密支付
export const pollingTao = data => post('/api/PurAccount/pollingSearchForTaoTe', data)
//批量下单
export const manyDisOrder = data => post('/api/DisOrder/manyDisOrder', data)
//批量申请退款
export const manyRefund = data => post('/api/DisOrder/manyRefund', data)
//刷新订单数据
export const synDisOrder = data => post('/api/DisOrder/synDisOrderInfo', data)
//批量支付
export const manyPay = data => post('/api/DisOrder/manyPay', data)
//订单数量和金额统计
export const statistics = data => post('/api/Shop/statistics', data)
//表格数据
export const getTableData = data => post('/api/Shop/getTableData', data)
//获取商品销售排行前7
export const sevenGoods = data => post('/api/Shop/sevenGoods', data)
//折线图数据
export const foldLineData = data => post('/api/Shop/foldLineChartData', data)
//常见问题
export const getProblem=data=>post("/api/Shop/getProblem",data)
export const checkAccount=data=>post("/api/PurAccount/checkAccount",data)

//获取抖音组所需参数
export const getDouyinNotice = data => post("/api/shop/getIProps",data)










