<script>
import {
    cancelOrder,
    disOrderList,
    disOrderSyn, disOrderTotal,
    getGoodsDetail,
    getGoodsList, getLogistics,
    getSkuInfo, manyDisOrder, manyPay,
    orderNow, payOrder,
    purchaseOrder, synDisOrder,
} from "@/api/department";
import lbb from "@/assets/pages/1688.png";
import TT from "@/assets/pages/TT.jpg";

export default {
    name: 'orderComponent',
    data() {
        return {
            refundReason: [],
            TT: TT,
            lbb: lbb,
            active: 0,
            activeName: '待下单',
            bye_time: [
                {
                    value: '1',
                    label: '今天'
                },
                {
                    value: '2',
                    label: '昨天'
                },
                {
                    value: '3',
                    label: '本周'
                },
                {
                    value: '4',
                    label: '本月'
                },
            ],
            tabPane: [
                {
                    id: 1,
                    name: '待下单',
                    msg: "待下单状态下的订单，为抖音推送订单",
                    postSelect: {
                        time_type: '',
                        order_id: ''
                    },
                    isIndeterminate: false,
                    getbug: false,
                    orderlist: [],
                    page: 1,
                    limite: 10,
                    total: 0,
                    valueNum: 10,
                },
                {
                    id: 2,
                    isIndeterminate: false,
                    getbug: false,
                    name: '下单出错',
                    msg: "下单出错状态下的订单，为分销采购订单",
                    postSelect: {
                        time_type: '',
                        order_id: ''
                    },
                    orderlist: [],
                    page: 1,
                    limite: 10,
                    total: 0,
                    valueNum: 12
                },
                {
                    id: 3,
                    name: '已下单待付款',
                    getbug: false,
                    postSelect: {
                        time_type: '',
                        order_id: ''
                    },
                    msg: "已下单待付款状态下的订单，为分销采购订单",
                    orderlist: [],
                    isIndeterminate: false,
                    page: 1,
                    limite: 10,
                    total: 0,
                    valueNum: 12
                },
                {
                    id: 4,
                    msg: "待发货状态下的订单，为分销采购订单",
                    name: '待发货',
                    page: 1,
                    limite: 10,
                    total: 0,
                    postSelect: {
                        time_type: '',
                        order_id: ''
                    },
                    orderlist: [],
                    valueNum: 12,

                },
                {
                    id: 5,
                    msg: "已发货状态下的订单，为分销采购订单",
                    page: 1,
                    limite: 10,
                    total: 0,
                    name: '已发货',
                    postSelect: {
                        time_type: '',
                        order_id: ''
                    },
                    orderlist: [],

                    valueNum: 12
                },
                {
                    id: 6,
                    msg: "交易成功状态下的订单，为分销采购订单",
                    page: 1,
                    limite: 10,
                    total: 0,
                    name: '交易成功',
                    postSelect: {
                        time_type: '',
                        order_id: ''
                    },
                    orderlist: [],
                    valueNum: 12
                },
                {
                    id: 7,
                    msg: "退款中状态下的订单，为分销采购订单",
                    name: '退款中',
                    postSelect: {
                        time_type: '',
                        order_id: ''
                    },
                    page: 1,
                    limite: 10,
                    total: 0,
                    orderlist: [],
                    valueNum: 12
                },
                {
                    id: 8,
                    msg: "已取消状态下的订单，为分销采购订单",
                    name: '已取消',
                    postSelect: {
                        time_type: '',
                        order_id: ''
                    },
                    orderlist: [],
                    page: 1,
                    limite: 10,
                    total: 0,
                    valueNum: 12
                },
                {
                    id: 9,
                    msg: "已隔离订单状态下的订单，为分销采购订单",
                    name: '已隔离订单',
                    postSelect: {
                        time_type: '',
                        order_id: ''
                    },
                    orderlist: [],
                    page: 1,
                    limite: 10,
                    total: 0,
                    valueNum: 12
                }
            ],
            loadingGet: false,
            syncData: {
                pickerValue: [],
                order_num: '',
            },
            getPayTrue: false,
            getLoding: false,
            loading: true,
            diaVisible: false,
            activeFET: 0,
            orderGet: {},
            diaVisibles: false,
            getAllGoods: [],
            index: 0,
            cansel: [],
            urlCode: '',
            payVisible: false,
            loadingTao: true,
            getTaote: false,
            deliveryData: [],
            deliverVisible: false,
            syncVisible: false,
            type: 1,
            getLIst: "",
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            getPaylist: [],
            getPay: [],
        }

    },
    activated() {
        this.getDisTotal();
        this.handleClick(0, 1,1);
    },
    methods: {
        copyValue(id) {
            const that = this;
            this.$copyText(id).then(
                function () {
                    that.$message({
                        message: "复制成功!",
                        type: 'success'
                    });
                },
                function () {
                    that.$message({
                        message: "复制失败!",
                        type: 'error'
                    });
                }
            );
        },
        getDisTotal() {
            disOrderTotal().then(res => {
                console.log(res)
                const data = res.data
                for (let i = 0; i < data.length; i++) {
                    for (let j = 0; j < this.tabPane.length; j++) {
                        if (data[i].id === this.tabPane[j].id) {
                            this.tabPane[j].valueNum = data[i].total
                        }
                    }
                }
            })
        },
        handleClick(tab, el,page) {
            let index = 0
            if (tab === 0) {
                index = this.tabPane.findIndex(item => item.id === el)
            } else {
                index = this.tabPane.findIndex(item => item.name === tab.name)
            }
            const data = {
                order_id: this.tabPane[index].postSelect.order_id,
                page:page?page:'',
                order_status: this.tabPane[index].id,
                limit: this.tabPane[index].limite
            }
            this.getDisTotal()
            disOrderList(data).then(res => {
                if (res.code === 1) {
                    this.tabPane[index].getbug = false
                    this.tabPane[index].isIndeterminate = false
                    let i = 0;
                    this.tabPane[index].orderlist = res.data.data.map(item => {
                        item.pay_time = new Date(item.pay_time * 1000).toLocaleString()
                        if (index === 0) {
                            if (this.getPaylist.find(el => el.order_id === item.order_id)) {
                                i += 1;
                                item.check = true
                            } else {
                                item.check = false
                            }
                        } else if (index === 1) {
                            if (this.getPay.find(el => el.order_id === item.order_id)) {
                                i += 1;
                                item.check = true
                            } else {
                                item.check = false
                            }
                        } else if (index === 2) {
                            if (this.cansel.find(el => el.order_id === item.order_id)) {
                                i += 1;
                                item.check = true
                            } else {
                                item.check = false
                            }
                        }
                        return item
                    })
                    if (i === this.tabPane[index].orderlist.length) {
                        this.tabPane[index].getbug = true
                    } else if (i > 0 && i < this.tabPane[index].orderlist.length) {
                        this.tabPane[index].isIndeterminate = true
                    } else {
                        this.tabPane[index].getbug = false
                        this.tabPane[index].isIndeterminate = false
                    }
                   // console.log(this.tabPane[index])
                    this.tabPane[index].valueNum = res.data.total
                    this.tabPane[index].total = res.data.total
                    this.loading = false
                } else {
                    this.$notify({
                        message: res.msg,
                        type: 'error'
                    })
                }

            })

        },
        checkTure(item, arr, i) {
            if (arr.find(el => el.order_id === item.order_id)) {
                i += 1;
                item.check = true
                return i
            } else {
                item.check = false
            }

        },
        postList(el, val) {
            const index = this.tabPane.findIndex(item => item.id === val)
            const formGet = this.tabPane.find(item => item.id === val)?.postSelect;
            let data = {}
            if (formGet.time_type === null) {
                data = {
                    order_status: val,
                    page: 1,
                    begin_time: "",
                    end_time: "",
                    limit: 10,
                    order_id: formGet.order_id,
                }
            } else {
                data = {
                    order_status: val,
                    page: 1,
                    begin_time: formGet.time_type[0]?.getTime() || "",
                    end_time: formGet.time_type[1]?.getTime() || "",
                    limit: 10,
                    order_id: formGet.order_id,
                }
            }
            this.getDisTotal()
            disOrderList(data).then(res => {
                if (res.code === 1) {
                    this.tabPane[index].orderlist = res.data.data.map(item => {
                        if (index === 0) {
                            this.checkTure(item, this.getPaylist)
                        } else if (index === 1) {
                            this.checkTure(item, this.getPay)
                        } else if (index === 2) {
                            this.checkTure(item, this.cansel)
                        }
                        item.pay_time = new Date(item.pay_time * 1000).toLocaleString()
                        return item
                    })
                    this.tabPane[index].valueNum = res.data.total
                    this.tabPane[index].total = res.data.total
                    this.$notify({
                        title: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$notify({
                        title: res.msg,
                        type: 'error'
                    })
                }
                this.loading = false
            })

        },
        gotCount(val) {
            if (val === 1) {
                this.$router.push({path: '/PurchaseAccount'});
            } else if (val === 2) {
                this.$router.push({path: '/DistributionGoods'});
            } else if (val === 3) {
                this.syncOrder()
            }
            if (this.active++ > 2) this.active = 0;
        },
        syncOrder() {
            this.syncVisible = true
        },
        postSync() {
            let data = {}
            if (this.type === 1) {
                const create_time_start = this.syncData.pickerValue[0]?.getTime()
                const create_time_end = this.syncData.pickerValue[1]?.getTime()
                data = {
                    type: this.type,
                    create_time_start: create_time_start,
                    create_time_end: create_time_end,
                    order_num: ''
                }
            } else {
                data = {
                    type: this.type,
                    create_time_start: "",
                    create_time_end: "",
                    order_num: this.syncData.order_num
                }
            }
            disOrderSyn(data).then(res => {
                if (res.code === 1) {
                    this.syncVisible = false
                    this.$notify({
                        message: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$notify({
                        message: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        handleSizeChange(el, val) {
            const index = this.tabPane.findIndex(item => item.id == el)
            this.tabPane[index].limite = val
            this.handleClick(0, el)
        },
        handleCurrentChange(el, val) {
            const index = this.tabPane.findIndex(item => item.id == el)
            this.tabPane[index].page = val
            this.handleClick(0, el,val)
        },
        syncItem(id, el) {
            synDisOrder({order_id: id}).then(res => {
                if (res.code === 1) {
                    this.handleClick(0, el)
                    this.$message.success(res.msg)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },

        dioVisible(val) {
            const data = {order_id: val}
            orderNow(data).then(res => {
                if (res.code === 1) {
                    this.orderGet = res.data
                    this.diaVisible = true
                    this.activeFET = 0
                    this.$notify({
                        title: res.msg,
                        type: 'success'
                    })


                } else {
                    this.$notify({
                        title: res.msg,
                        type: 'error'
                    })
                }
            })

        },
        getID3() {
            this.diaVisible = false
            this.handleClick(0, 3);
            this.activeName = '已下单待付款'
        },

        nextGet(val) {
            if(this.orderGet.do_order_one){
                this.getLoding = true
                let son_order = []
                for (let i = 0; i < this.orderGet.do_order_one.length; i++) {
                    son_order.push(this.orderGet.do_order_one[i].goods_bind_url_one)
                }
                const data = {
                    order_id: val,
                    son_order: son_order,
                }
                purchaseOrder(data).then(res => {
                    console.log(res)
                    this.getLoding = false
                    if (res.code === 1) {

                        //进入一键支付
                        if (this.activeFET++ > 2) this.activeFET = 0;
                        this.$notify({
                            title: res.msg,
                            type: 'success'
                        })
                        // eslint-disable-next-line no-empty
                    } else if (res.code === 199) {
                        this.$message.warning(res.msg)
                    } else {
                        this.diaVisible = false
                        this.activeName = '下单出错'
                        this.handleClick(0, 2)
                        this.$notify({
                            title: res.msg,
                            type: 'error'
                        })
                    }
                })
            }

        },
        getHandle(value, index) {
            this.orderGet.do_order_one[index].goods_bind_url_one.num = value
        },
        taoT(val, index) {
            if (this.loadingTao === false) {
                this.loadingTao = true
            }
            this.getAllGoods = []
            this.diaVisibles = true
            const data = {
                img_url: val
            }
            getGoodsList(data).then(res => {
                if (res.code === 1) {
                    this.loadingTao = false
                    this.getAllGoods = res.data
                    this.$notify({
                        title: res.msg,
                        type: 'success'
                    })
                } else if (res.code === 106) {
                    this.diaVisible = false
                    this.diaVisibles = false
                    this.loadingTao = false
                    this.$message.warning(res.msg + ",正在跳转账号绑定...")
                    setTimeout(() => {
                        this.$router.push({path: '/PurchaseAccount'})
                    }, 1000)
                } else {
                    this.loadingTao = false
                    this.$notify({
                        title: res.msg,
                        type: 'error'
                    })
                }
                this.index = index
            })

            this.activeFET = 0
        },
        getId(id) {
            this.diaVisibles = false
            const data = {goods_id: id}
            getGoodsDetail(data).then(res => {
                const skuData = res.data.data.map(item => {
                    item.attributesText = item.attributesText + '-' + '￥' + item.finalPrice
                    return item
                })
                if (res.code === 1) {
                    this.$notify({
                        title: res.msg,
                        type: 'success'
                    })
                    const index = this.index
                    this.orderGet.do_order_one[index].goods_bind_url_one.sku_id = ''
                    this.orderGet.do_order_one[index].goods_bind_url_one.goods_id = res.data.goods_id
                    this.orderGet.do_order_one[index].goods_bind_url_one.good_url = res.data.url
                    this.orderGet.do_order_one[index].skuData = skuData
                } else {
                    this.$notify({
                        title: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        getSpec(value, index) {
            console.log(value)
           const data = this.orderGet.do_order_one[index].skuData.find(el=>{
                return el.skuId===value
            })
            console.log(data)
            this.orderGet.do_order_one[index].goods_bind_url_one.specId=data.specId
            this.orderGet.do_order_one[index].goods_bind_url_one.sku_id =value
            this.orderGet.do_order_one[index].goods_bind_url_one.sku_text=data.attributesText
        },
        getDetail(val, index) {
            getSkuInfo({url: val}).then(res => {
                if (res.code === 1) {
                    const skuData = res.data.data.map(item => {
                        item.attributesText = item.attributesText + '-' + '￥' + item.finalPrice
                        return item
                    })
                    this.orderGet.do_order_one[index].skuData = skuData
                    this.orderGet.do_order_one[index].goods_bind_url_one.goods_id = res.data.goods_id
                    this.$notify({
                        title: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$notify({
                        title: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        getIem(val, id, index, check) {
            if (id === 3) {
                this.getRide(val, id, index, check, this.cansel)
            } else if (id === 1) {
                this.getRide(val, id, index, check, this.getPaylist)
            } else {
                this.getRide(val, id, index, check, this.getPay)
            }
        },
        getRide(val, id, index, check, arr) {
            if (check) {
                const items = this.tabPane[id - 1].orderlist.find(el => {
                    return el.order_id === val
                })
                arr.push(items);

            } else {
                this.tabPane[id - 1].orderlist[index].check = false
                const indexs = arr.findIndex(el => el.order_id === this.tabPane[id - 1].orderlist[index].order_id)
                arr.splice(indexs, 1)
            }
            const i = this.loopIsInd(id, arr)
            if (this.tabPane[id - 1].orderlist.length === i) {
                this.tabPane[id - 1].getbug = true
                this.tabPane[id - 1].isIndeterminate = false
            } else if (i > 0 && i <= this.tabPane[id - 1].orderlist.length) {
                this.tabPane[id - 1].isIndeterminate = true
            } else {
                this.tabPane[id - 1].getbug = false
                this.tabPane[id - 1].isIndeterminate = false
            }
        },
        loopIsInd(id, arr) {
            let ik = 0
            for (let i = 0; i < this.tabPane[id - 1].orderlist.length; i++) {

                if (arr.find(el => el.order_id === this.tabPane[id - 1].orderlist[i].order_id)) {
                    ik += 1;
                }

            }
            return ik;
        },
        loopSelect(val, arr, getArr, get) {
            if (get === 1) {
                for (let i = 0; i < arr.length; i++) {
                    if (!getArr.find(el => el.order_id === arr[i].order_id)) {
                        getArr.push(arr[i])
                    }
                    this.tabPane[val - 1].orderlist.map(item => {
                        item.check = true
                    })
                }
            } else if (get === 2) {
                for (let i = 0; i < arr.length; i++) {
                    const index = getArr.findIndex(el => el.order_id === arr[i].order_id)
                    if (index !== -1) {
                        getArr.splice(index, 1)
                    }
                    this.tabPane[val - 1].orderlist.map(item => {
                        item.check = false
                    })
                }
            }
        },
        getAllCheck(val, check) {
            let arr = []
            arr = this.tabPane[val - 1].orderlist
            if (check) {
                this.tabPane[val - 1].getbug = true
                this.tabPane[val - 1].isIndeterminate = false
                if (val === 3) {
                    this.loopSelect(val, arr, this.cansel, 1)
                } else if (val === 1) {
                    this.loopSelect(val, arr, this.getPaylist, 1)
                } else {
                    this.loopSelect(val, arr, this.getPay, 1)
                }
            } else {
                if (val === 1) {
                    this.loopSelect(val, arr, this.getPaylist, 2)
                } else if (val === 3) {
                    this.loopSelect(val, arr, this.cansel, 2)
                } else {
                    this.loopSelect(val, arr, this.getPay, 2)
                }
            }
        },

        getUrl(value, index) {
            this.orderGet.do_order_one[index].goods_bind_url_one.good_url = value
        },
        canselChange() {
            if (this.cansel.length === 0) {
                this.$message.warning("请勾选要取消的订单")
            } else {
                let order_id = []
                for (let i = 0; i < this.cansel.length; i++) {
                    order_id.push(this.cansel[i].order_id)
                }
                const data = {order_id}
                this.cansel = []
                cancelOrder(data).then(res => {
                    if (res.code === 1) {
                        this.$notify({
                            title: res.msg,
                            type: 'success'
                        })
                        this.activeName = "待下单"
                        setTimeout(() => {
                            this.handleClick(0, 1)
                        }, 2000)
                    } else {
                        this.$notify({
                            title: res.msg,
                            type: 'error'
                        })
                    }
                })
            }

        },
        getSonPay(val) {
            const data = {order_id: val.order_id, pay_type: 2}
            payOrder(data).then(res => {
                if (res.code === 1) {
                    if (val.dis_order_one.order_type == 2) {
                        window.open(res.data.payUrl, '_blank')
                    } else {
                        this.getTaote = true;
                        this.urlCode = 'data:image/jpeg;base64,' + res.data.qrCodeUrl;
                    }
                    this.$notify({
                        title: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$notify({
                        title: res.msg,
                        type: 'error'
                    })
                }

            })
        },
        getParentPay(val) {
            const data = {order_id: val[0].order_id, pay_type: 2}
            payOrder(data).then(res => {
                if (res.code === 1) {
                    if (val[0].dis_order_one.order_type == 2) {
                        window.open(res.data.payUrl, '_blank')
                    } else {
                        this.getTaote = true;
                        this.urlCode = 'data:image/jpeg;base64,' + res.data.qrCodeUrl;
                    }
                    this.$notify({
                        title: res.msg,
                        type: 'success'
                    })
                } else {
                    this.$notify({
                        title: res.msg,
                        type: 'error'
                    })
                }
            })
        },
        jumpWrite(val) {
            this.$router.push({
                path: '/DistributionGoods',
                query: {
                    order_id: val
                }
            })
        },
        getFHUO() {
            this.getTaote = false
            this.activeName = '待发货'
            this.handleClick(0, 4)
        },
        delivery(val) {
            const data = {order_id: val}
            getLogistics(data).then(res => {
                if (res.code === 1) {
                    this.deliverVisible = true;
                    this.deliveryData = res.data.length == 0 ? [] : res.data;
                    // this.$message.success(res.msg)
                }else if(res.code === 3){
                    this.$message.warning(res.data[0].msg)
                }
                else {
                    this.$message.error(res.msg)
                }
            })
        },
        handleType(tab) {
            if (tab.index === "0") {
                this.type = 1
            } else {
                this.type = 2
            }
        },
        getPayVisible(val) {
            if ((val === 1 && this.getPaylist.length !== 0) || (val === 2 && this.getPay.length !== 0) || (val === 3 && this.cansel.length !== 0)) {
                this.payVisible = true
                this.getLIst = val
            } else {
                this.$message.warning("请勾选下单订单");
            }

        },
        subPay() {
            this.loadingGet = true
            let arr = []
            if (this.getLIst === 1) {
                for (let i = 0; i < this.getPaylist.length; i++) {
                    arr.push(this.getPaylist[i].order_id)
                }
            } else if (this.getLIst === 3) {
                for (let i = 0; i < this.cansel.length; i++) {
                    arr.push(this.cansel[i].order_id)
                }
            } else {
                for (let i = 0; i < this.getPay.length; i++) {
                    arr.push(this.getPay[i].order_id)
                }
            }
            const data = {order_ids: arr}
            const datas = {order_id: arr}
            if (this.getLIst === 1) this.getPaylist = []
            else if (this.getLIst === 2) this.getPay = []
            else this.cansel = []
            if (this.getLIst === 1 || this.getLIst === 2) {
                manyDisOrder(data).then(res => {
                    this.payVisible = false
                    this.loadingGet = false
                    if (res.code === 1) {
                        this.$notify({
                            type: 'success',
                            message: res.msg
                        })
                        this.activeName = "已下单待付款"
                        this.handleClick(0, 3)
                    } else {
                        this.$notify({
                            type: 'error',
                            message: res.msg
                        })
                        this.activeName = "下单出错"
                        this.handleClick(0, 2)
                    }
                })
            } else {
                manyPay(datas).then(res => {
                    console.log(res)
                    this.payVisible = false
                    this.loadingGet = false
                    if (res.code === 1) {
                        if (res.data.type === 2) {
                            this.$message.success("正在前往支付......")
                            setTimeout(() => {
                                window.open(res.data.payUrl, '_blank');
                                this.getPayTrue = true
                            }, 2000)
                        } else if (res.data.type === 3) {
                            this.getTaote = true
                            this.urlCode = 'data:image/jpeg;base64,' + res.data.qrCodeUrl;
                        }


                    } else {
                        this.$notify({
                            type: 'error',
                            message: res.msg
                        })
                    }

                })
            }
        },
        getFour() {
            this.getPayTrue = false
            this.activeName = "待发货"
            this.handleClick(0, 4)
        }
    }
}
</script>
<template>
    <div class="bg-white rounded shadow">
        <div class="mx-8 px-6 pt-3 box-border flex ">
            <div class="w-3/4 backAll">
                <el-steps :active="active" finish-status="success">
                    <el-step title="设置采购账号" description="">
                        <div slot="description" class="flex items-center text-clip" style="width: 107px">
                            <el-button @click="gotCount(1)" type="text">去设置，</el-button>
                            <span>采购账号</span>
                        </div>
                    </el-step>
                    <el-step title="绑定货源">
                        <div slot="description" class="flex items-center text-clip" style="width: 123px">
                            <span>支持1688货源</span>
                            <el-button @click="gotCount(2)" type="text">去设置</el-button>
                        </div>
                    </el-step>
                    <el-step title="同步订单">
                        <div slot="description" class="flex items-center text-clip" style="width: 138px">
                            <el-button @click="gotCount(3)" type="text">获取</el-button>
                            <span>当前账号待采购订单</span>
                        </div>
                    </el-step>
                    <el-step title="开始下单">
                    </el-step>
                </el-steps>
            </div>
            <div class="w-1/4 flex">
                <div class="wgetB" @click="syncOrder">
                    <i class="el-icon-refresh mb-2"></i>
                    <span>同步订单</span>
                </div>
            </div>
        </div>
        <div class="mx-8 px-6 box-border">
            <div class="tabSt">
                <el-tabs>
                    <el-tabs v-model="activeName" @tab-click="handleClick">

                        <el-tab-pane :key="item.id" :name="item.name" v-for="item in tabPane">
                            <div slot="label">
                                {{ item.name }}({{ item.valueNum }})
                            </div>
                            <div class="mb-2">
                                <el-alert close-text="知道了" type="warning" show-icon>
                                    {{ item.msg }}
                                </el-alert>
                            </div>

                            <div class="flex items-center">
                                <div>
                                    <el-form :inline="true" ref="form" :model="item.postSelect">
                                        <el-form-item label="订单编号:">
                                            <el-input v-model="item.postSelect.order_id"
                                                      placeholder="请输入订单编号">
                                            </el-input>
                                        </el-form-item>
                                        <el-form-item label="创建时间:">
                                            <!--                                        <el-select v-model="item.postSelect.time_type">-->
                                            <!--                                            <el-option :key="items.value" v-for="(items) in  bye_time"-->
                                            <!--                                                       :label="items.label"-->
                                            <!--                                                       :value="items.value">-->
                                            <!--                                            </el-option>-->
                                            <!--                                        </el-select>-->
                                            <el-date-picker
                                                v-model="item.postSelect.time_type"
                                                type="datetimerange"
                                                :picker-options="pickerOptions"
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                end-placeholder="结束日期"
                                                align="right">
                                            </el-date-picker>
                                        </el-form-item>
                                        <el-form-item>
                                            <el-button type="primary" @click="postList(0,item.id)">查询</el-button>
                                        </el-form-item>
                                    </el-form>
                                </div>
                                <div class="getMt flex ">
                                    <div class="getSTYs " @click="canselChange"
                                         v-if="item.orderlist.length !==0 && item.id===3">
                                        ({{ cansel.length }})取消采购
                                    </div>


                                </div>

                            </div>
                            <div v-loading="loading" class="getHscool getCursor">
                                <!--                                <div class="my-3">-->
                                <!--                                    <el-alert-->
                                <!--                                        v-if="item.orderlist.length==0"-->
                                <!--                                        title="暂无待处理数据"-->
                                <!--                                        type="info">-->
                                <!--                                    </el-alert>-->
                                <!--                                </div>-->
                                <el-empty v-if="item.orderlist.length===0"></el-empty>
                                <el-card shadow="hover" class="enbox-w mb-4" :key="itemE.order_id"
                                         v-for="(itemE,index) in item.orderlist">
                                    <div slot="header" class="header clearfix flex items-center    p-1.5 text-sm">
                                        <el-checkbox v-model="itemE.check" v-if="item.id===3||item.id===1||item.id===2"
                                                     @change="getIem(itemE.order_id,item.id,index,itemE.check)"
                                                     class="pr-1.5 pl-4"></el-checkbox>
                                        <div class="pl-4 flex items-center">
                                            <span>订单号:</span>
                                            <span class="text-green-600 ">{{ itemE.order_id }}</span>
                                        </div>
                                        <div class=" flex items-center">
                                            <el-button type="text" @click="copyValue(itemE.order_id)" size="mini">
                                                <i class="el-icon-document-copy"></i>
                                            </el-button>&nbsp;
                                        </div>
                                        <span class="pl-4  text-red-500 text-base font-bold">￥{{
                                                itemE.pay_amount
                                            }}</span>
                                        <span class="pl-4">支付时间：<span class="font-bold">{{
                                                itemE.pay_time
                                            }}</span></span>
                                        <div class="pl-4">
                                            <el-button type="text"
                                                       @click="syncItem(itemE.order_id,item.id)">
                                                <i class="el-icon-refresh">刷新</i>
                                            </el-button>
                                        </div>
                                    </div>
                                    <div class="flex flex-col "
                                         v-for="(items) in itemE.order_dis_son"
                                         :key="items.order_id">
                                        <div class="flex justify-between  text-gray-500 px-3 py-1">
                                            <div class="flex justify-start">
                                                <div class="flex-col">
                                                    <el-image
                                                        class="rounded-md posiget"
                                                        style="width: 70px; height: 70px "
                                                        :src="items.product_pic"
                                                        fit="fill">
                                                        <div slot="error" class="image-slot">
                                                            <i class="el-icon-picture-outline"></i>
                                                        </div>
                                                    </el-image>
                                                </div>

                                                <div></div>
                                                <div class=" flex flex-col mx-2">
                                                    <div class="flex WgET items-baseline">
                                                        <el-tooltip effect="light" :content="items.product_name"
                                                                    placement="top-start">
                                                            <span class="text-green-600 ">{{ items.product_name }}</span>
                                                        </el-tooltip>

                                                        <el-button @click="getSonPay(items)" v-if="item.id==3"
                                                                   type="text">
                                                            <i class="el-icon-thumb"></i>支付
                                                        </el-button>
                                                    </div>
                                                    <span>ID:{{ items.product_id }}</span>
                                                    <div class="flex">
                                                 <span :key="itemSpec.name" v-for="itemSpec in items.order_spec">
                                            {{ itemSpec.name }}：{{ itemSpec.value }};</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="flex flex-col justify-center">
                                                <span>金额：￥{{ items.pay_amount }}</span>
                                                <span class="text-black  ml-2">x&nbsp;&nbsp;{{
                                                        items.item_num
                                                    }}件</span>
                                            </div>
                                            <div class="flex flex-col justify-center" v-if="item.id==1||item.id==2">
                                                <a class=" " v-if="items.goods_bind_url !==null"
                                                   :href="items.goods_bind_url==null?'':items.goods_bind_url.good_url"
                                                   target="_blank">查看货源</a>
                                                <el-button @click="jumpWrite(items.product_id)" type="text"
                                                           v-if="items.goods_bind_url !==null">
                                                    编辑货源
                                                </el-button>
                                                <span v-else class=" ">未绑定货源，<el-button
                                                    @click="jumpWrite(items.product_id)" type="text">
                                                    去绑定
                                                </el-button>
                                                </span>
                                                <!--                                                <router-link  to="{path:'/DistributionGoods',query:'order_id'}" ><span-->
                                                <!--                                                   >去绑定</span></router-link>-->
                                            </div>
                                        </div>
                                        <div v-if="(item.id!==1)&&(item.id!==2)"
                                             class="px-3 pb-1 text-sm text-gray-600  border-b">
                                            <div class="flex" v-if="items.dis_order_one.error_msg==''">
                                                <el-image
                                                    style="width: 23px; height: 23px "
                                                    :src="items.dis_order_one.order_type==2?lbb:TT"
                                                >
                                                </el-image>
                                                <div class="pl-4">采购单号：<span class="text-green-600  ">{{
                                                        items.dis_order_one.dis_order_id
                                                    }}</span></div>
                                                <div class="pl-4">采购数量：<span
                                                    class="   ">{{ items.dis_order_one.num }}</span>
                                                </div>
                                                <div class="pl-4">采购金额：<span
                                                    class=" text-red-500  ">￥{{ items.dis_order_one.money }}</span>
                                                </div>
                                                <div class="pl-4">创建时间：{{
                                                        new Date(items.dis_order_one.createtime * 1000).toLocaleString()
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="(item.id==2)" class="WgET flex  text-red-500 pl-1.5 ">
                                            <el-tooltip effect="light" :content="items.dis_order_one.error_msg"
                                                        placement="bottom">
                                                <span>采购失败！！（{{ items.dis_order_one.error_msg }}）</span>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <div class="flex justify-between items-center bg-gray-50 px-3 py-1 ">
                                        <div class="flex border-t-gray-100 text-sm  ">
                                            <span>{{ itemE.order_detailed.mask_post_receiver }}</span>
                                            <span>({{ itemE.order_detailed.mask_post_tel }})</span>&nbsp;
                                            <span>{{ itemE.order_detailed.province_name }}</span>&nbsp;
                                            <span>{{ itemE.order_detailed.city_name }}</span>&nbsp;
                                            <span>{{ itemE.order_detailed.town_name }}</span>&nbsp;
                                            <span>({{ itemE.order_detailed.mask_address }})</span>
                                        </div>
                                        <div class="getButton" v-if="item.id==1||item.id==2||item.id==3||item.id==5">
                                            <el-button class="p-1" v-if="item.id==1" @click="dioVisible(itemE.order_id)"
                                                       style="float: right; padding: 3px 0"
                                                       type="text">立即下单
                                            </el-button>
                                            <el-button class="p-1" v-else-if="item.id==2"
                                                       @click="dioVisible(itemE.order_id)"
                                                       style="float: right; padding: 3px 0"
                                                       type="text">重新下单
                                            </el-button>
                                            <el-button @click="getParentPay(itemE.order_dis_son)" class="p-1"
                                                       v-else-if="item.id==3"
                                                       style="float: right; padding: 3px 0"
                                                       type="text">立即支付
                                            </el-button>
                                            <el-button @click="delivery(itemE.order_id)" class="p-1"
                                                       v-else-if="item.id==5"
                                                       style="float: right; padding: 3px 0"
                                                       type="text">查看物流
                                            </el-button>
                                        </div>
                                    </div>
                                </el-card>
                            </div>
                            <div class="m-2 flex justify-center" v-if="item.orderlist.length!==0">
                                <div class="flex justify-around items-center" v-if="item.id===1||item.id===2||item.id==3">
                                    <div class="mx-2" >
                                        <el-checkbox :indeterminate="item.isIndeterminate" v-model="item.getbug"
                                                     @change="(val)=>getAllCheck(item.id,val)">
                                            本页全选
                                        </el-checkbox>
                                    </div>

                                    <el-button  type="primary" @click="getPayVisible(item.id)"
                                    >
<!--                                      class="cursor-pointer getSTY  ml-2"   -->
                                        {{ (item.id === 1) || (item.id === 2) ? "批量下单" : "批量支付" }} ({{
                                            item.id === 1 ? getPaylist.length : item.id === 2 ? getPay.length : item.id === 3 ? cansel.length : " "
                                        }})
                                    </el-button>
                                </div>
                                <div>
                                    <el-pagination
                                        @size-change="(val)=>handleSizeChange(item.id,val)"
                                        @current-change="(val)=>handleCurrentChange(item.id,val)"
                                        :current-page="item.page"
                                        :page-sizes="[10, 20, 30, 40]"
                                        :page-size="100"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="item.total">
                                    </el-pagination>
                                </div>

                            </div>


                        </el-tab-pane>
                    </el-tabs>
                </el-tabs>
            </div>
        </div>
        <el-dialog
            :visible.sync="deliverVisible"
            width="40%"
        >
            <div :key="item.data.logisticsId" v-for="item in deliveryData">
                <div class="rounded-lg border-blue-600 getColor p-4 mb-2.5 flex text-sm">
                    <div class="getParent">
                        <el-image style="width: 60px ;height: 60px" :src="item.data.product_pic">
                        </el-image>
                        <div class="getSon text-white font-extralight text-xs">共{{ item.data.num }}件</div>
                    </div>
                    <div class="ml-2  text-gray-400">
                        <div>{{ item.data.product_name }}</div>
                        <div>采购单号：{{ item.data.dis_order_id }}</div>
                        <div class="flex">
                             <span :key="items.name" v-for="items in item.data.specs">
                                {{ items.name }}:{{ items.value }};
                            </span>
                        </div>
                    </div>
                </div>
                <div class="rounded-lg border-blue-600 getColor ">
                    <div class="mb-2.5 flex p-4 text-base text-black">
                        运单号：{{ item.data.dis_order_id }}
                    </div>
                    <div class="p-4">
                        <el-timeline :reverse="true">
                            <el-timeline-item

                                :class="{
                  eltimelinefocus: index===item.data.detail.length-1
                }"
                                :icon="index===item.data.detail.length-1?'el-icon-time':''"
                                :color="index===item.data.detail.length-1?'#e9feec':''"
                                v-for="(itemE,index) in item.data.detail"
                                :key="index"
                                :timestamp="itemE.gmtCreate"
                                placement="top">
                                    <span
                                        :style="{color:index===item.data.detail.length-1?'#e9feec':'',fontSize:index===item.data.detail.length-1?18+'px':''}">{{
                                            itemE.standerdDesc
                                        }}</span>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                    <div class="border-t-2 border-white p-4 flex items-center p-4">
                        <div class="bg-wiget rounded-full">
                            <span class="border-red-400 base font-semibold">收</span>
                        </div>
                        <div class="ml-2">
                            {{
                                item.data.address.mask_post_receiver + " " + item.data.address.mask_post_tel + " " + item.data.address.province_name + " " + item.data.address.city_name + " " + item.data.address.town_name + " " + item.data.address.mask_address
                            }}
                        </div>
                    </div>
                </div>

            </div>
        </el-dialog>
        <el-dialog
            title="支付提示"
            :visible.sync="getPayTrue"
            width="30%"
        >
            <span>是否已完成支付</span>
            <span slot="footer" class="dialog-footer">
    <el-button @click="getPayTrue = false">否</el-button>
    <el-button type="primary" @click="getFour">已支付，跳转待发货界面</el-button>
  </span>
        </el-dialog>
        <el-dialog
            :visible.sync="diaVisible"
            width="80%">
            <el-dialog
                :visible.sync="diaVisibles"
                append-to-body
            >
                <!--                    <el-card :loading="loadingTao" :key="itemP.id"-->
                <!--                             v-for="itemP in getAllGoods">-->
                <el-table
                    border
                    v-loading="loadingTao"
                    :header-cell-style="{textAlign:'center'}"
                    :cell-style="{textAlign:'center'}"
                    :data="getAllGoods"
                    style="width: 100%">
                    <template slot="empty">
                        <el-empty></el-empty>
                    </template>
                    <el-table-column
                        prop="itemSquarePic"
                        label="商品图片">
                        <template v-slot:default="scope">
                            <el-image style="width: 50px;height: 50px"
                                      :src="scope.row.itemSquarePic"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="itemTitle"
                        label="商品标题">
                    </el-table-column>
                    <el-table-column
                        prop="itemPrice"
                        label="商品价格">
                    </el-table-column>
                    <el-table-column label="操作">
                        <template v-slot:default="scope">
                            <el-button size="mini" @click="getId(scope.row.id)">选择</el-button>
                        </template>

                    </el-table-column>
                </el-table>
                <!--                    </el-card>-->
            </el-dialog>
            <div>
                <span>订单号:{{ orderGet.order_id }}</span>
                <el-steps :active="activeFET" finish-status="success" simple>
                    <el-step title="确认订单"></el-step>
                    <el-step title="一键支付"></el-step>
                    <el-step title="下单成功"></el-step>
                </el-steps>
                <div class="getMess" v-if="activeFET==0">
                    <ul class="p-4">
                        <li>
                            <el-card class="border rounded p-2 my-3" :key="item.goods_id"
                                     v-for="(item,index) in orderGet.do_order_one">
                                <form id="item">
                                    <div class="flex py-1.5 items-center">
                                        <label class="mx-2">商品标题</label>
                                        <div class="disabledGet">
                                            <span>{{ item.product_name }}</span>
                                        </div>
                                    </div>
                                    <div class="flex py-1.5 items-center">
                                        <label class="mx-2">规格</label>
                                        <div class="disabledGet">
                                                        <span :key="itemSpec.name" v-for="itemSpec in item.order_spec">
                                                            {{ itemSpec.name }}：{{ itemSpec.value }};
                                                        </span>
                                        </div>
                                    </div>
                                    <div class="flex py-1.5 items-center">
                                        <label class="mx-2">采购商品地址</label>
                                        <div class="flex">
                                            <el-input @change="(value)=>getUrl(value,index)"
                                                      placeholder="请输入采购订单地址"
                                                      v-model="item.goods_bind_url_one.good_url"></el-input>
                                            <el-button @click="getDetail(item.goods_bind_url_one.good_url,index)"
                                                       type="primary" size="mini">查询
                                            </el-button>
<!--                                            <el-button slot="reference"-->
<!--                                                       @click="taoT(item.product_pic,index)"-->
<!--                                                       size="mini">搜淘特同款-->
<!--                                            </el-button>-->
                                        </div>
                                    </div>
                                    <div class="flex py-1.5 items-center">
                                        <label class="mx-2">采购数量</label>
                                        <el-input-number v-model="item.goods_bind_url_one.num"
                                                         @change="(value)=>getHandle(value,index)"
                                                         :min="1"></el-input-number>
                                    </div>
                                    <div class="flex py-1.5 items-center">
                                        <label class="mx-2">采购商品规格</label>
                                        <el-select @change="(value)=>getSpec(value,index)"
                                                   v-model="item.goods_bind_url_one.sku_id"
                                                   placeholder="请商品规格">
                                            <el-option class="my-1" :key="itemK.skuId" v-for="itemK in item.skuData"
                                                       :value="itemK.skuId" :label="itemK.attributesText">
                                                <div class="flex items-center">
                                                    <el-image style="width: 50px ;height: 50px ;border-radius: 0" :src="itemK.img"></el-image>
                                                    {{ itemK.attributesText }}
                                                </div>
                                            </el-option>
                                        </el-select>
                                    </div>


                                </form>

                            </el-card>

                        </li>
                    </ul>
                    <div class="mt-2.5 flex justify-end">
                        <el-button @click="diaVisible = false">取 消</el-button>
                        <el-button :loading="getLoding" type="primary" @click="nextGet(orderGet.order_id)">确 定
                        </el-button>
                    </div>
                </div>
                <div v-if="activeFET==1">
                    <div class="itemFlex">
                        <i class="el-icon-success"></i>
                        <h3>订单创建成功</h3>
                        <span>采购订单创建成功，请前往支付</span>
                        <el-button type="primary" @click="getID3">前往支付</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
        <el-dialog
            :visible.sync="payVisible"
            width="60%">
                <span slot="title">
                     {{
                        (getLIst === 1) || (getLIst === 2) ? '批量下单' : '批量支付'
                    }}（共{{
                        getLIst === 1 ? getPaylist.length :
                            getLIst === 2 ? getPay.length : cansel.length
                    }}笔）</span>
            <el-table
                height="500"
                :data="getLIst === 1 ? getPaylist:getLIst === 2?getPay:cansel"
                style="width: 100%">
                <template slot="empty">
                    <el-empty></el-empty>
                </template>
                <el-table-column
                    align="center"
                    label="订单信息">
                    <template v-slot="scope">
                        <div>
                            <p>订单编号：{{ scope.row.order_id }}</p>
                            <p>(<span class="text-red-500">￥{{
                                    (getLIst === 1) || (getLIst === 2) ? scope.row.pay_amount : scope.row.dis_total_money
                                }}</span>)</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="买家信息">
                    <template v-slot="scope">
                        <div>
                            <p>买家姓名：{{ scope.row.order_detailed.buyer_words }}
                                ({{ scope.row.order_detailed.mask_post_tel }})</p>
                            <p>{{
                                    scope.row.order_detailed.province_name
                                    + " " + scope.row.order_detailed.city_name
                                    + " " + scope.row.order_detailed.town_name
                                    + " " + scope.row.order_detailed.street_name
                                    + " " + scope.row.order_detailed.mask_address
                                }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="pay_time"
                    label="下单或支付时间">
                </el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
    <el-button @click="payVisible = false">取 消</el-button>
    <el-button :loading="loadingGet" type="primary" @click="subPay">{{ getLIst === 3 ? "批量支付" : "批量采购下单" }}</el-button>
  </span>
        </el-dialog>
        <el-dialog
            title="牛牛工具-淘特支付"
            :visible.sync="getTaote"
            width="30%"
        >
            <div class="justify-center items-center flex flex-col">
                <h2 class=" ">请使用淘特APP扫码支付哦！</h2>
                <el-image style="width: 200px;height: 200px " :src="urlCode">
                </el-image>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="getTaote = false">否</el-button>
    <el-button type="primary" @click="getFHUO">已支付</el-button>
  </span>

        </el-dialog>
        <el-dialog
            title="平台数据迁移至此账号"
            :visible.sync="syncVisible"
            width="40%"
        >
            <el-tabs @tab-click="handleType" class="getTabs" tab-position="left" style="height: 200px;">
                <el-tab-pane label="批量同步">
                    <div class="flex-col items-start wgetO p-4">
                        <span>时间段：</span>
                        <el-date-picker
                            class="w-3/4"
                            v-model="syncData.pickerValue"
                            type="datetimerange"
                            :picker-options="pickerOptions"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            align="right">
                        </el-date-picker>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="精准同步">
                    <div class="flex-col items-start wgetO p-4">
                        <span>订单编号：</span>
                        <el-input placeholder="请输入订单编号" v-model="syncData.order_num"></el-input>
                    </div>

                </el-tab-pane>
            </el-tabs>
            <span slot="footer" class="dialog-footer">
    <el-button @click="syncVisible = false">取 消</el-button>
    <el-button type="primary" @click="postSync">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<style>
.getCursor {
    cursor: pointer;
}

.getMt {
    margin-top: -21px;
}

.el-tabs__item.is-active {
    border: 1px solid #019943 !important;
    border-radius: 11px 11px 0 0;
    color: white !important;
    background-color: #019943 !important;
}

.el-tabs__nav-wrap::after {
    background-color: #019943 !important;
}

.getSTY {
    padding: 4px 12px;
    height: 32px;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 4px;
    color: white;
    background-color: #e9feec;
    border: 1px solid #e9feec;
}
.getSTYs{
    padding: 4px 7px;
    height: 32px;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 4px;
    color: #e9feec;
    /*background-color: #e9feec;*/
    border: 1px solid #e9feec;
}
.getSTYs:hover{
    transition:all 0.4s ease;
    color: white;
    background-color: #e9feec;
}
.enbox-w .header {
    background-color: #f5f7f9;
}

.wgetB {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 120px;
    justify-content: center;
    flex-direction: column;
    color: #019943 !important;
}

.wgetB i {
    font-weight: bolder;
    font-size: 30px;

}

.wgetB:hover {
    cursor: pointer;
    /*color: #019943 !important;*/
}

.backAll {
    background-color: #f7f9fa;
    border-radius: 12px;
    cursor: pointer;
    padding: 11px;
    margin-right: 18px;
}

.heightKO {
    height: 600px;
    overflow-y: auto;
}

.getWith {
    width: 180px;
}

.getParent {
    position: relative;
}

.getColor {
    background-color: aliceblue;
}

.getSon {
    bottom: 5px;
    width: 100%;
    text-align: center;
    border-radius: 0 0 5px 5px;
    height: 20px;
    background-color: rgba(137, 137, 137, 0.5);
    position: absolute;
}

.bg-wiget {
    display: flex;
    justify-content: center;
    width: 35px;
    height: 35px;
    cursor: pointer;
    border: none;
    background: #e7e7e7;
    align-items: center;
    box-shadow: 0 0 3px 1px #1818183d, 2px 2px 3px #18181865, inset 2px 2px 2px #ffffff;
}

.el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding: 0 20px !important;
}

.el-tabs--top .el-tabs__item.is-top:last-child {
    padding: 0 20px !important;
}

.getHscool {
    width: 100%;
    overflow: auto;
    max-height: 480px;
}

.WgET span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 1000px;
}

.WgET .el-button {
    font-weight: bolder;
    color: #e9feec;
    width: 50px;
    box-sizing: border-box;
    border: 1px solid #e9feec;
    margin-left: 5px;
    padding: 3px !important;
}

.WgET .el-button:hover {
    font-weight: bolder;
   color: white;
    background-color: #e9feec;
    width: 50px;
    box-sizing: border-box;
    border: 1px solid #e9feec;
    margin-left: 5px;
    padding: 3px !important;
}

.getButton {
    color: #e9feec;
    border: 1px solid #e9feec;
    border-radius: 2.3rem;
    padding: 3px 8px;
}
.getButton:hover{
    transition:all 0.4s ease;
    background-color: #e9feec;
}
.getButton .el-button--text {
    color: #e9feec !important;
}
.getButton:hover .el-button--text {
    color: white !important;
}

.disabledGet {
    padding: 6px 10px;
    background-color: #F5F7FA;
    color: #C0C4CC;
    cursor: not-allowed;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
}

.getMess ul {
    border-radius: 8px;
    border-left: 1px solid #DCDFE6;
    border-bottom: 1px solid #DCDFE6;
    height: 400px;
    overflow-y: auto;
}

.itemFlex .el-icon-success {
    font-size: 55px;
    color: green;
}

.itemFlex {
    padding: 33px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.itemFlex span {
    padding: 5px;
}

.itemFlex h3 {
    font-weight: bolder;
    font-size: 20px;
    padding: 5px;
}

.tabSt .el-tabs__item {
    font-size: 15px;
    font-weight: bolder;
}

</style>
