import Service from "./request";
import JSONbig from 'json-bigint';
import Qs from "qs";
 export  const get = (url,params={})=>{
     return Service({
         url,
         method:"GET",
         params,
         transformResponse: data => {
             return JSONbig.parse(data)
         }
     })
 }
export  const post = (url,data={})=>{
    return Service({
        url,
        method:"POST",
        transformRequest: [function (data) {
            // 对 data 进行任意转换处理
            return Qs.stringify(data)
            }],
        data:data

    })
}
