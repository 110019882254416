<script>
import {
    AddressIndex, addShopAddress, editShopAddress,
    getAddressDetail,
    getAddressList,
    getAreasByProvince,
    getProvince
} from "@/api/department";

export default {
    name: 'addressVue',
    data() {
        return {
            addressData: [],
            addressTotal: 0,
            addressPage: 1,
            addressLimit: 10,
            dialogAddress: false,
            AddressDate: {
                street_id: '',
                address_id: '',
                reciever_name: '',
                phone: '',
                receiver_detail: '',
                remark: '',
                receiver_provinc: '',
            },
            props: {
                value: 'code',
                label: 'name',
                muliple: true,
                emitPath: false,
                lazy: true,
                lazyLoad: (node, resolve) => {
                    const {level, value} = node;
                    this.lazyLoadNext(level, value, resolve)

                }
            },
            provicen: [],
            addressTwo: [],
            addressThree: [],
            AddressVisible: true,
            loading: true,
            form: {
                reciever_name: [
                    {required: true, message: '请输入寄件人姓名', trigger: 'blur'},
                ],
                phone: [
                    {required: true, message: '请输入寄件人姓名', trigger: 'blur'},
                    {min: 11, max: 11, message: '长度为11位的数字', trigger: 'blur'}
                ],
                receiver_provinc: [
                    {required: true, message: '请选择省市区', trigger: 'change'},
                ],
                receiver_detail: [
                    {required: true, message: '请输入详细地址', trigger: 'blur'},
                ]
            },
            getLoading: true
        }
    },
    watch: {},
    mounted() {
        this.getDate()

    },
    methods: {
        reWrite(val) {
            this.AddressVisible = true
            const data = {address_id: val}
            getAddressDetail(data).then(res => {
                const data = res.data
                this.AddressDate = data
                this.AddressDate.receiver_provinc = data.receiver_provinc + '/' + data.receiver_city + '/' + data.receiver_district + '/' + data.receiver_street
            })
            this.dialogAddress = true
            if (this.dialogAddress) {
                getProvince().then(res => {
                    this.provicen = res.data

                })
            }

        },
        async postAddress(val) {
            //手机号验证
            if (!/^1[3456789]\d{9}$/.test(this.AddressDate.phone)) {
                this.$message.error('请输入正确的手机号')
            } else {
                if (val !== "") {
                    const data = {
                        user_name: this.AddressDate.reciever_name,
                        mobile: this.AddressDate.phone,
                        detail: this.AddressDate.receiver_detail,
                        remark: this.AddressDate.remark,
                        street_id: typeof (this.AddressDate.receiver_provinc) === "number" ? this.AddressDate.receiver_provinc : this.AddressDate.street_id,
                        address_id: val
                    }
                    await editShopAddress(data).then(res => {
                        this.dialogAddress = false
                        if (res.code == 1) {
                            this.getDate()
                            this.$message.success(res.msg)
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    const data = {
                        user_name: this.AddressDate.reciever_name,
                        mobile: this.AddressDate.phone,
                        detail: this.AddressDate.receiver_detail,
                        remark: this.AddressDate.remark,
                        street_id: this.AddressDate.receiver_provinc,
                    }
                    await addShopAddress(data).then(res => {
                        this.dialogAddress = false
                        if (res.code == 1) {
                            this.getDate()
                            this.$message.success(res.msg)
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                }

            }

        },
        addAddress() {
            this.AddressDate = {
                street_id: '',
                address_id: '',
                reciever_name: '',
                phone: '',
                receiver_detail: '',
                remark: '',
                receiver_provinc: '',
            }
            this.AddressVisible = false
            this.dialogAddress = true
            if (this.dialogAddress) {
                getProvince().then(res => {
                    this.provicen = res.data
                })
            }
        },
        lazyLoadNext(level, value, resolve) {
            let stop_level = 4
            let list = []
            if (level == 1) {
                const data = {province_id: value}
                getAreasByProvince(data).then(res => {
                    this.addressTwo.push(res.data)
                    resolve(res.data)
                })
            } else if (level == 2) {
                for (let i = 0; i <= this.addressTwo.length; i++) {
                    const data = this.addressTwo[i]?.find(item => item.code == value)?.sub_districts
                    if (data || data?.code == value) {
                        this.addressThree.push(data)
                    }
                    resolve(data)
                }
            } else if (level == 3) {
                for (let i = 0; i <= this.addressTwo.length; i++) {
                    const data = this.addressThree[i]?.find(item => item.code == value)?.sub_districts
                    resolve(data)
                }
            } else {
                resolve(
                    (list || []).map(ele => {
                        return Object.assign(ele, {
                            leaf: level >= stop_level,
                        })
                    })
                )
            }
        },
        getDate() {
            this.getLoading = true
            const data = {page: this.addressPage, limit: this.addressLimit}
            AddressIndex(data).then(res => {
                if (res.code == 1) {
                    this.getLoading = false
                    this.addressData = res.data.data.map(item => {
                        item.idDefault = (item.is_default == 1) || (item.is_send_default == 1) ? '默认' : '设为默认'
                        item.createtime = new Date(item.create_time * 1000).toLocaleString()
                        item.headerSEX = item.reciever_name.slice(0, 1)
                        return item
                    })
                    this.addressTotal = res.data.total
                } else {
                    this.$notify({
                        title: res.msg,
                        type: 'error'
                    });
                    this.getLoading = false
                }

            })

        },
        handleSizeChange(val) {
            this.addressLimit = val
            this.getDate()
        },
        handleCurrentChange(val) {
            this.addressPage = val
            this.getDate()
        },
        synchronization() {
            getAddressList().then(res => {
                this.$message.success(res.msg);
            })
        },
        getCode(val) {
            this.AddressVisible = val.toString().includes('/') ? true : false
        }
    }
}
</script>
<template>
    <div>
        <div class="    pb-3  ">
            <div>
                <el-button type="primary" @click="addAddress">新增地址</el-button>
                <el-button @click="synchronization">同步数据</el-button>
            </div>
            <div class="flex flex-col items-center m-2  rounded my-20" v-if="addressData.length === 0">
                <el-empty description="还未创建过地址"></el-empty>
            </div>
            <div class="pt-3 my-8 bg-white border rounded-md " v-loading="getLoading" v-if="addressData.length!==0">
                <el-row :gutter="16" class="minwGet">
                    <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="6" v-for="item in addressData"
                            :key="item.address_id">
                        <el-card class=" box-w mx-3 my-1 " shadow="hover"
                        >
                            <div slot="header" class=" clearfix ">
                                <div class="bg-wigetjt rounded-full">
                                    <span class="border-red-400 base font-semibold">{{ item.headerSEX }}</span>
                                </div>
                                <div class="marginget">
                                    <button v-if="item.idDefault==='默认'" class="defaultSty"
                                            :disabled="item.idDefault=='默认'?true:false"
                                            style="float: right; "
                                            :type="item.idDefault=='默认'?'primary':'text'">{{ item.idDefault }}
                                    </button>
                                    <el-button @click="reWrite(item.address_id)"
                                               style="float: right; "
                                               type="text">修改
                                    </el-button>
                                </div>
                            </div>
                            <div class="  leading-7">
                                <div><span class="text-gray-400 limitHi ">收/发货人：<span
                                    class="text-gray-900">{{ item.reciever_name }}</span></span></div>
                                <div><span class="text-gray-400 ">所在地区：
                           <span class="text-gray-900">{{ item.receiver_provinc }}&nbsp;&nbsp;
                                {{ item.receiver_city }}&nbsp;&nbsp;
                                {{ item.receiver_district }}</span> </span></div>
                                <div><span class="text-gray-400 limitHi ">地址：
                                      <el-popover
                                          placement="top-start"
                                          title="地址"
                                          width="200"
                                          trigger="hover"
                                          :content="item.receiver_detail">
                                            <span slot="reference"
                                                  class="text-gray-900">{{ item.receiver_detail }}</span>
                                      </el-popover>

                                </span></div>
                                <div><span class="text-gray-400 ">手机号：-<span
                                    class="text-gray-900">{{ item.phone }}</span></span></div>
                                <div>
                                    <span class="text-gray-400 limitHi">备注：
                                     <el-popover
                                         placement="top-start"
                                         title="备注"
                                         width="200"
                                         trigger="hover"
                                         :content="item.remark">
                                     <span slot="reference"
                                           class="text-gray-900">
                                         {{ item.remark }}
                                     </span>
                                      </el-popover>

                                </span></div>
                                <div><span class="text-gray-400 ">创建时间：<span
                                    class="text-gray-900">{{ item.createtime }}</span></span></div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>

                <div class="flex flex-col items-center m-2" v-if="addressData.length!==0">
                    <el-pagination
                        v-if="addressData.length!==0"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="addressPage"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="100"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="addressTotal">
                    </el-pagination>
                </div>
            </div>
            <el-dialog
                v-loading="loading"
                title="新增寄件人姓名"
                :visible.sync="dialogAddress"
                width="50%">
                <el-form :rules="form" ref="form" :model="AddressDate" label-position="right" label-width="100px">
                    <el-form-item label="寄件人姓名" prop="reciever_name">
                        <el-input v-model="AddressDate.reciever_name"></el-input>
                    </el-form-item>
                    <el-form-item label="寄件人手机" prop="phone">
                        <el-input v-model="AddressDate.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="省市区" prop="receiver_provinc">
                        <el-cascader @change="getCode" v-model="AddressDate.receiver_provinc" :options="provicen"
                                     :props="props"></el-cascader>
                        <span class=" ">{{ AddressVisible ? AddressDate.receiver_provinc : "" }}
                       </span>
                    </el-form-item>
                    <el-form-item label="详细地址" prop="receiver_detail">
                        <el-input v-model="AddressDate.receiver_detail"></el-input>
                    </el-form-item>
                    <el-form-item label="售后备注">
                        <el-input type="textarea" v-model="AddressDate.remark"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class=" dialog-footer">
                    <el-button @click="dialogAddress = false">取 消</el-button>
                    <el-button type="primary" @click="postAddress(AddressDate.address_id)">确 定</el-button>
                </span>
            </el-dialog>

        </div>

    </div>
</template>
<style>
.limitHi {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

/*背景渐变*/
.minwGet {
    max-height: 700px;
    overflow-y: auto;
}

.defaultSty {
    color: #000000;
    text-decoration: none;
    font-size: 14px;
    border: none;
    /*background: none;*/
    /*font-weight: 500;*/
    /*font-family: 'Poppins', sans-serif;*/
}

.defaultSty::before {
    margin-left: auto;
}

.defaultSty::after, .defaultSty::before {
    content: '';
    width: 0%;
    height: 2px;
    background: #e9feec;
    display: block;
    transition: 0.5s;
}

.defaultSty:hover::after, .defaultSty:hover::before {
    width: 100%;
}

.marginget {
    display: flex;
    margin-top: -40px;
    justify-content: flex-end;
}

.box-w {
    /*-webkit-box-flex: 0;*/
    /*display: block;*/
    /*flex: 0 0 23%;*/
    /*max-width: 50%;*/
    border-left: 3px solid #019943 !important;
}

.bg-wigetjt {
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    background-color: #019943;
    justify-content: center;
    align-items: center;
    border: 1px solid #e9feec !important;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}


.el-card__body {
    padding-top: 0 !important;
}


.item {
    margin-bottom: 18px;
}

.el-card__header {
    border: none !important;
}

</style>