<script>
import {
    bindPurchaseAccount,
    delPurAccount, freePaymentForAli,
    freePaymentForTao,
    getPurAccount,
    getPurchaseAccount, pollingAli, pollingTao
} from "@/api/department";

export default {
    name: 'PurchaseAccount',
    data() {
        return {
            accountVisible: false,
            requestAccountList: [],
            url: '',
            tempCode: '',
            url1688: '',
            accountList: [],
            boxHiden: false,
            img_url: "",
            taoteVision: false,
            getAliNULL: null,
            getTaoNull: null,
            RightVisible: false
        }
    },
    watch: {},
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            getPurchaseAccount().then(res => {
                const url = localStorage.getItem('url')
                this.requestAccountList = res.data.map(item => {
                    item.image = url + item.image
                    return item
                })
                console.log( this.requestAccountList)
            })
            getPurAccount().then(res => {
                this.accountList = res.data.map(item => {
                    item.is_free_pay = item.is_free_pay === "1" ? true : false
                    return item
                })
            })
        },
        bindAccount(id,isValidate) {
            this.accountVisible = false
            const data = {
                sourceTypeId: id,
                isValidate:isValidate?isValidate:''
            }
            bindPurchaseAccount(data).then(res => {
                if (id === 3 && res.code === 101) {
                    this.tempCode = res.data.tempCode
                    this.url = res.data.url
                    this.jump()
                } else if (id === 2 && res.code === 101) {
                    const url1688 = res.data.url
                    setTimeout(() => {
                        window.open(url1688, '_blank');
                        this.RightVisible = true
                    })
                } else if (res.code === 1) {
                    this.$message.success(res.msg)
                    this.refresh();
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        jump() {
            let jumpTaote = this.$router.resolve({
                name: "taoTe",
                query: {
                    tempCode: this.tempCode,
                    url: this.url
                }
            })
            window.open(jumpTaote.href, '_blank');
        },

        deleteID(val) {
            const data = {
                id: val
            }
            delPurAccount(data).then(res => {
                if (res.code === 1) {
                    this.$message.success(res.msg)
                } else {
                    this.$message.error(res.msg)
                }
                this.getData()
            })
        },
        getStatus(val, item) {
            const index = this.accountList.findIndex(el => el.source_type.title == item)
            this.accountList[index].is_free_pay = val ? false : true
            if (item === "淘特货源") {
                const data = {
                    is_free_pay: val ? 1 : 2
                }
                freePaymentForTao(data).then(res => {
                    if (res.code === 1) {
                        this.$notify({
                            type: "success",
                            message: res.msg
                        })
                        this.getData()
                    } else if (res.code === 105) {
                        this.taoteVision = true
                        this.img_url = "data:image/jpeg;base64," + res.data.img_url
                        let i = 0;
                        this.getAliNULL = setInterval(() => {
                            pollingTao().then(res => {
                                i += 1;
                                if (res.code == 1 || i === 5) {
                                    clearInterval(this.getAliNULL)
                                    this.getData()
                                }
                            })
                        }, 2000)
                    } else {
                        this.$notify({
                            type: 'error',
                            message: res.msg
                        })
                    }
                })
            } else {
                const data = {
                    is_free_pay: val ? 1 : 2
                }
                freePaymentForAli(data).then(res => {
                    if (res.code === 1) {
                        this.$notify({
                            type: "success",
                            message: res.msg
                        })
                        this.getData()
                    } else if (res.code === 105) {
                        this.$notify({
                            type: "success",
                            message: '正在跳转1688验证'
                        })
                        setTimeout(() => {
                            window.open(res.data.url, '_blank');
                        }, 1000)

                        let i = 0;
                        this.getTaoNull = setInterval(() => {
                            pollingAli().then(res => {
                                i += 1
                                if (res.code == 1 || i >= 5) {
                                    clearInterval(this.getTaoNull)
                                    this.getData()
                                }
                            })
                        }, 2000)
                    } else {
                        this.$notify({
                            type: 'error',
                            message: res.msg
                        })
                    }
                })
            }
        },
        handleClose() {
            this.loopGet()
            this.getData()
        },
        loopGet() {
            const data = {
                sourceTypeId: 2
            }
            bindPurchaseAccount(data).then(res => {
                this.RightVisible = false
                if (res.code == 1) {
                    this.$message.success('绑定成功')
                }
            })
        },
        refresh() {
            this.getData()
        },
        get1688() {
            this.bindAccount(2,1)
            this.RightVisible = false
        }
    }

}
</script>
<template>
    <div class=" minW flex-1  px-8 py-4  bg-white shadow">
<!---->
        <el-button type="primary" @click="accountVisible=true">绑定采购账号</el-button>
        <el-button type="primary" @click="refresh"  icon="el-icon-refresh" plain >刷新数据</el-button>
        <span class="text-gray-400  font-medium">（绑定成功后请点击刷新数据）</span>
        <div class="  p-8 text-gray-500 flex-col my-8 " v-if="accountList.length==0">
            <div class="flex   justify-center"><i class="text-gray-500  text-4xl  el-icon-warning"></i></div>
            <div class="flex text-2xl  justify-center"><h1>完成采购账号授权后，可以自动同步订单和物流</h1></div>
            <div class=" flex  justify-center">未绑定,请先完成1688采购账号绑定授权再使用</div>
        </div>
        <div class="my-3">
            <ul class="flex  flex-wrap">
                <li class="flip-card " :key="item.id" v-for="(item) in accountList">
                    <div class="flip-card-inner">
                        <div class="flip-card-front shadow">
                            <div class="getCorlor flex justify-between">
                                <span class=" ">{{ item.source_type.title }}</span>
                                <div>
                                    <el-button @click="deleteID(item.id)"   type="text" size="mini">
                                        <span class="getBorder">
                                            解除
                                        </span>
                                    </el-button>
                                    <span v-if="item.is_end===1"
                                          @click="bindAccount(item.source_type_id)"
                                          class="getBorder">续订</span>
                                    <span >
                                    （{{ item.is_end === 1 ? "已到期" : "未到期" }}）
                                   </span>
                                </div>

                            </div>
                            <div>
                                <span class="title"><p class="whitespace-nowrap">采购账号：
                                 </p>{{
                                        item.account
                                    }}</span>

                                <p class="font-bold">是否开启免密支付
                                    <el-switch
                                        @change="(val)=>getStatus(val,item.source_type.title)"
                                        v-model="item.is_free_pay">
                                    </el-switch>
                                </p>

                            </div>
                            <div>
                                <span>授权到期时间：
                                    {{ new Date(item.auth_end_time * 1000).toLocaleString() }}
                                </span>
                            </div>

                        </div>
                    </div>
                </li>

            </ul>
        </div>
        <el-dialog
            title="1688授权"
            :before-close="handleClose"
            :visible.sync="RightVisible"
            width="30%"
        ><span>是否已授权1688账号</span>
            <span slot="footer" class="dialog-footer">
    <el-button @click="RightVisible = false">否</el-button>
    <el-button type="primary" @click="get1688()">已授权</el-button>
  </span>
        </el-dialog>
        <el-dialog
            title="货源平台"
            :visible.sync="accountVisible"
            width="60%">
            <ul class="flex flex-wrap ">
                <li class="border getW mx-2 my-2 rounded-lg" :key="item.id" v-for="item in requestAccountList">
                    <div class="flex-col gotBorder border-b p-3 items-center justify-around">
                        <div class="flex items-center">
                            <el-image style="width: 60px;" :src="item.image">
                            </el-image>
                            <span class="font-bold">{{ item.title }}</span>
                        </div>
                        <div class="p-3">
                            <span class="text-gray-500">{{ item.desc }}</span>
                        </div>
                    </div>
                    <div class="getH flex flex-col items-center p-4 justify-between">
                        <span>{{ item.content }}</span>
                        <div>
                            <el-button @click="bindAccount(item.id)" type="primary">
                                绑定采购账号
                            </el-button>
                        </div>
                    </div>
                </li>
            </ul>
        </el-dialog>
        <el-dialog
            title="淘特免密支付"
            :visible.sync="taoteVision"
            width="30%">
            <div class="flex flex-col justify-between items-center">
                <el-image
                    style="width: 300px;height: 300px;"
                    :src="img_url"
                ></el-image>
                <span>请使用淘特App扫码</span>
            </div>
            <!--            <span slot="footer" class="dialog-footer">-->
            <!--    <el-button @click="taoteVision = false">否</el-button>-->
            <!--    <el-button type="primary" @click="isTaote">已授权</el-button>-->
            <!--  </span>-->
        </el-dialog>
    </div>
</template>
<style>
.getCorlor{
    font-size: medium;
    font-weight: 700;
}
.getBorder {
    font-weight: 700;
    font-size: medium;
    color: #019943;
    border-bottom: 1px solid #019943;
}

.flip-card {
    margin: 5px 20px;
    background-color: transparent;
    width: 370px;
    height: 254px;
    perspective: 1000px;
    font-family: sans-serif;
}

.title {
    font-size: 1.5em;
    font-weight: 900;
    text-align: center;
    margin: 0;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

/*.flip-card:hover .flip-card-inner {*/
/*    transform: rotateY(180deg);*/
/*}*/
/*.flip-card-front span{*/
/*    white-space: nowrap;*/
/*    text-overflow: ellipsis;*/
/*}*/
.flip-card-front {
    cursor: pointer;
    /*box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);*/
    position: absolute;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    border-color: rgba(219, 234, 254, 1);
   border-radius: 10px;
    backface-visibility: hidden;
    background-color: rgb(231, 252, 235);
    /*background: linear-gradient(120deg, bisque 60%, rgb(255, 231, 222) 88%, rgb(255, 211, 195) 40%, rgb(12, 39, 235) 48%);*/
    /*color: #e9feec;*/
}

.flip-card-front {
    /*background: linear-gradient(120deg, bisque 60%, rgb(255, 231, 222) 88%,*/
    /*rgb(255, 211, 195) 40%, rgb(12, 39, 235) 48%);*/
    /*color: #e9feec;*/
}


.getFont h4 {
    font-size: 14px;
    color: #626262;
}


.getH {
    height: 200px;
}

.minW {
    min-width: 700px;
}
.gotBorder{
    border-bottom: 1px solid #cccccc;
}
.getW {
    border:1px solid #cccccc;
    width: 350px  ;
}
</style>