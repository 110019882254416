<script>
import {getProblem} from "@/api/department";

export default {
    name:'GETpROM',
    data(){
        return{
            getCONTENT:''
        }
    },
    created() {
        getProblem().then(res=>{
            this.getCONTENT=res.data.content
            console.log(res)
        })
    },
    methods:{

    }

}
</script>
<template>
    <div class="bg-white rounded shadow ">
        <div class="p-4  flex flex-col items-center">
            <p class="text-3xl">常见问题</p>
            <div class="py-8" v-html="getCONTENT">

            </div>

        </div>

    </div>
</template>