<template>
    <div id="app">

        <router-view/>
    </div>

</template>
<script>
localStorage.setItem('url','https://dyfxdfapi.niuniucrm.com/')
</script>
<style>
body {
    margin: 0;
}
#app{
    padding: 0;
    margin: 0;
    font-family: iconfont;
    height: 100%;
}
html , body {
    font-family: iconfont;
    height: 100%;
}
#nprogress .bar{
    background: #e9feec !important;
}
</style>
