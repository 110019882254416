import {post,get} from "@/api/index"


// 获取1688商品分类6
export const productType = params=>get("api/GoodsSourceCenter/getAlibabaCategory",params)

// 获取商品列表6
export const productList = params=>get("api/GoodsSourceCenter/getFxGoodsList1688",params)

// 1688铺货
export const distribution = data=>post("shop/Move/distribution1688",data)

// 查看是否授权
export const checkIsEmpower = params =>get("shop/Move/isAuth1688",params)