// import {post,get} from "./index"
import {post,get} from "@/api/index"
// 查询是否授权授权状态6
export const getBUserPerms = params => get("api/GoodsSourceCenter/getAuthTaote",params)

// 查看授权结果
export const getAuthTaoteStatus = params => get("shop/Move/getAuthTaoteStatus",params)

// 获取商品类目6
export const getBItemCategory = params => get("api/GoodsSourceCenter/getCategoryList",params)

// 搜索商品类目
export const searchBItemCategory = params => get("shop/Move/serCategoryList",params)

// 获取商品池配置信息6
export const getBItemPool = params => get("api/GoodsSourceCenter/getPoolList",params)

// 获取商品列表
export const getTaoteList = params => get("api/GoodsSourceCenter/getTaoteList",params)

// 获取淘特榜单分类
export const getManualSelectionGoods = params => get("shop/Move/getArtificialrankList",params)

// 获取淘特榜单商品
export const getBillboardGoods = params=>get("shop/Move/getRankItemDetail",params)

// 获取类目榜单
export const getCategoryRankingList = params => get("shop/Move/getRankList",params)

// 获取商品详情
export const getTaoteGoodsdetails = data => post("shop/Move/distributiontt",data)